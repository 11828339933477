export const menuItems = [
  {
    title: 'Company',
    // url: '/services',
    submenu: [
      {
        title: 'About us',
        // url: 'about-us',
        link: 'about-us'
      },
      {
        title: 'Our technology',
        // url: 'what-we-do',
        link: 'our-technology',
        // link: 'what-we-do'
      },
      {
        title: 'How we work',
        // url: 'how-we-work',
        link: 'how-we-work'
      },
      {
        title: 'Why work at Yozoi',
        // url: 'why-work-at-yozoi',
        link: 'why-work-at-yozoi',
      },
    ],
  },
  {
    title: 'Safety',
    // url: 'safety',
    link: 'safety',
  },
];

export const menuItems1 = [
  {
    title: 'Services',
    // url: '/services',
    submenu: [
      {
        title: 'Home support visits',
        url: 'home-visits',
        link: '/home-visits',
        submenu: [
          {
            title: 'Bedding',
            link: '/home-visits#bedding'
          },
          {
            title: 'Cleaning',
            link: '/home-visits#cleaning',
          },
          {
            title: ' Footcare',
            link: '/home-visits#footcare',
            // submenu: [
            //   {
            //     title: 'NodeJS',
            //     url: 'node',
            //   },
            //   {
            //     title: 'PHP',
            //     url: 'php',
            //   },
            // ],
          },
          {
            title: 'Gardening',
            link: '/home-visits#gardening',
          },
          {
            title: 'Haircare',
            link: '/home-visits#hair-care',
          },
          {
            title: 'Handyperson',
            link: '/home-visits#handyperson',
          },
          {
            title: 'Laundry',
            link: '/home-visits#laundry',
          },
          {
            title: 'Mobility assistance',
            link: '/home-visits#mobility-assistance',
          },
          {
            title: 'Pets',
            link: '/home-visits#pets',
          },
          {
            title: 'Preparing meals',
            link: '/home-visits#preparing-meals',
          },
          {
            title: 'Shopping assistance',
            link: '/home-visits#shopping-assistance',
          },
          {
            title: 'Security assistance',
            link: '/home-visits#security-assistance',
          },
        ],
      },
      {
        title: 'Companionship',
        url: 'companionship',
        link: '/companionship',
        submenu: [
          {
            title: 'Accompanying to community locations',
            link: '/companionship#accompanying-to-community-locations',
            // url: 'frontend',
          },
          {
            title: 'Cafes & restaurants',
            link: '/companionship#cafes-&-restaurants',
            // url: 'frontend',
          },
          {
            title: 'Keep fit',
            link: '/companionship#keep-fit',
            // url: 'frontend',
          },
          {
            title: ' Social activities',
            link: '/companionship#social-activities',
            // url: 'frontend',
          },
          {
            title: 'Visits to medics',
            link: '/companionship#visits-to-medics',
            // url: 'frontend',
          },
        ],
      },
      {
        title: 'Aware home system',
        url: '/aware-home-system',
        link: '/aware-home-system',
        submenu: [
          {
            title: 'Accident detection ',
            link: '/aware-home-system#accident-detection',
            // url: 'frontend',
            // url: '/',
            // link: '/'
          },
          {
            title: 'Intrusion detection',
            link: '/aware-home-system#intrusion-detection',
            // url: 'frontend',
            // url: '/',
            // link: '/'
          },
          // {
          //   title: 'Health & safety security ',
          //   // link: '/aware-home-system#keep-fit',
          //   // url: 'frontend',
          //   // url: '/',
          //   // link: '/'
          // },
          {
            title: ' Wellbeing security',
            link: '/aware-home-system#wellbeing-security',
            // url: 'frontend',
            // url: '/',
            // link: '/'
          },
        ],
      },
      {
        title: 'Child Care',
        url: 'ChildCare',
        link: '/ChildCare',
        submenu: [
          {
            title:'Philosophy',
            link: '/ChildCare#Philosophy',
            // url: 'frontend',
          },
          {
            title: 'School pick-ups and care',
            link: '/ChildCare#School-Pick-ups-and-care',
            // url: 'frontend',
          },
          {
            title: 'Home care visits',
            link: '/ChildCare#Home-care-visits',
            // url: 'frontend',
          },
          {
            title: ' Activities',
            link: '/ChildCare#Activities',
            // url: 'frontend',
          },
        ],
      },
    ],
  },
];
