import React from 'react'
import './SafetyStyles.scss';
import { Helmet } from 'react-helmet-async';
import banner from '../../assets/images/safety/banner.jpg';
import img1 from '../../assets/images/safety/safety 1.png';
import img2 from '../../assets/images/safety/safety 2.png';
import img3 from '../../assets/images/safety/safety 3.png';
import icon1 from '../../assets/images/safety/Features Of Yozoi App.svg';
import icon2 from '../../assets/images/safety/Security Clearance.svg';
import icon3 from '../../assets/images/safety/Real Time Identity Checks.svg';
import icon4 from '../../assets/images/safety/Support.svg';
import icon5 from '../../assets/images/safety/Emergency Assistance.svg';
import icon6 from '../../assets/images/safety/Yozoi Community Support.svg';
import icon7 from '../../assets/images/safety/Family Member Oversight.svg';
import icon8 from '../../assets/images/safety/2-Way Ratings.svg';
import ReadMoreReact from 'read-more-react';
import Slide from 'react-reveal/Slide';

const SafetyComponent = () => {
    return (<>
        <Helmet>
            <title>Yozoi | Safety</title>
            <meta name='description' content='Yozoi | Safety' />
            <meta name='keyWords' content='Yozoi | Safety' />
        </Helmet>

        <div className='safety'>
            <div className='safety__container'>
                <div className='safety__banner' style={{
                    backgroundImage: `url(${banner})`,
                    backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center top'
                }}>
                    <Slide left>
                        <h2>Safety</h2>
                    </Slide>
                </div>
                <div className='safety__sections'>
                    <div className='safety__section'>
                        <div className='safety__section__heading'>
                            {/* <Slide bottom cascade>
                                <h2>Safety</h2>
                            </Slide>
                            <div className='safety__section__line'></div> */}
                        </div>
                        <div className='safety__section__container'>
                            <div className='safety__section__container__left'>
                                <div className='safety__section__container__left__content'>
                                    {/* <h6>The best mobile app for domiciliary services</h6> */}
                                    <Slide right>
                                        <h4>Our dedication to ensuring your safety</h4>
                                        <p>We believe our customers and service providers deserve to receive and provide services to improve quality of life and to do so safely. Therefore, we are dedicated to your safety, from applying best possible standards and regulatory frameworks to embedding latest technologies in our app, all with the aim of ensure safety and wellbeing of both customers and service providers.</p>
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__section__container__right'>
                                <div className='safety__section__container__right__images'>
                                    <div className='safety__section__container__right__images__left'>
                                        <Slide left>
                                            <img src={img1} alt='' />
                                        </Slide>
                                    </div>
                                    <div className='safety__section__container__right__images__right'>
                                        <Slide left>
                                            <img src={img2} alt='' />
                                        </Slide>
                                        <Slide left>
                                            <img src={img3} alt='' />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='safety__built'>
                        <div className='safety__built__heading'>
                            <Slide bottom cascade>
                                <h2>Safety is built into our services</h2>
                            </Slide>
                        </div>
                        <div className='safety__cards'>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon1} alt='Features of Yozoi app' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>Features of Yozoi app</h3>
                                        <ReadMoreReact text={'Our app provides the ultimate in safety for customers and service providers through latest technology in sensors, data analytics and artificial intelligence. This integration of technology gives Yozoi app capability to detect safety and wellbeing issues and initiate interventions on the customer’s or service provider’s behalf. You can also request emergency assistance and customers can ask family or friends to monitor the service. The Yozoi app puts it all at your fingertips.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon2} alt='Security clearance' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>Security clearance</h3>
                                        <ReadMoreReact text={'All service providers undergo safety checks involving verification of identity, address and residential status and checks on any criminal history. Where appropriate, experience and qualification are checked and references obtained. All customers undergo safety checks involving verification of identity, address and residential status as well as checks on criminal and credit history. The checks are performed by the Yozoi app and all data is saved in the customers’ or service providers’ app.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon3} alt='Real time identity checks' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>Real time identity checks</h3>
                                        <ReadMoreReact text={'Yozoi conducts real-time identity check where a service provider is randomly selected and asked to take in real-time picture of themselves and the picture is then compared to their stored identity data. Yozoi also offers a keyless entry system where the service providers identity is checked in real-time by Yozoi installed equipment, on passing the identity check, the service provider is given keyless entry into customer’s home.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon4} alt='Support' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>Support</h3>
                                        <ReadMoreReact text={'Service providers can request support 24/7 from a specially trained team that can be contacted day or night with any questions or safety concerns.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon5} alt='Emergency assistance' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>Emergency assistance</h3>
                                        <ReadMoreReact text={'Customers and service providers can both tap a button for emergency assistance, Yozoi app responds according to specific protocols personalised for given customers and service providers. Yozoi app puts it all at your fingertips, giving you complete peace of mind.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon6} alt='Yozoi community support' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>Yozoi community support</h3>
                                        <ReadMoreReact text={'We are building a community of thousands of customers and service providers to share experiences and develop guidelines to help deliver services with outstanding safety standards.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon7} alt='Family member oversight' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>Family member oversight</h3>
                                        <ReadMoreReact text={'Yozoi app offers functionality enabling customers to nominate family or friends to keep oversight of their account and service in real-time.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                            <div className='safety__cards__container'>
                                <div className='safety__cards__container__icon'>
                                    <Slide bottom>
                                        <img src={icon8} alt='2-Way ratings' />
                                    </Slide>
                                </div>
                                <div className='safety__cards__container__content'>
                                    <Slide bottom>
                                        <h3>2-Way ratings</h3>
                                        <ReadMoreReact text={'Yozoi app allows customers and service providers to provide feedback and rating on each other. This is used by the Yozoi artificial intelligence to improve customer and service provider matching and further personalisation of services as well as assessing service provider’s training needs. Any severely negative ratings in terms of safety may cause Yozoi to place restrictions on customer or service provider accounts.'}
                                            min={80}
                                            ideal={100}
                                            max={1000}
                                            readMoreText={'Readmore'} />
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default SafetyComponent;