import React, { useState } from "react";
import "./service.scss";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import service from "../../../assets/images/about.jpg";
import { regex } from "../../components/utils/regex";
import Confirm from "../../components/confirmModal/confirm";
import Loader from "../../components/loader";
import { ForDomiciliaryServices } from "../../components/utils/apicalls";
import Slide from "react-reveal/Slide";
import CryptoJS from "crypto-js";
import Flag from "react-flagkit";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Checkbox } from "@mui/material";

const ServiceProvider = () => {
  const navigate = useNavigate();
  const [isToggle, setIsToggle] = useState(false);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [emailId, setEmailId] = useState();
  const [countryCode, setCountryCode] = useState("44");
  const [phoneNumber, setPhoneNumber] = useState();
  //   const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const [checked, setChecked] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [alertText, setAlertText] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModal1, setShowConfirmModal1] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorMessage4, setErrorMessage4] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormValid1, setIsFormValid1] = useState(false);
  const [isFormValid2, setIsFormValid2] = useState(false);
  const [isFormValid3, setIsFormValid3] = useState(false);
  const [isFormValid4, setIsFormValid4] = useState(false);

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmailId(value);

    // validate email format
    if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setIsFormValid2(false);
      setErrorMessage2("Invalid email format");
    } else {
      setIsFormValid2(true);
      setErrorMessage2("");
    }
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);

    // validate email format
    if (!value.match(/^([0|\+[0-9]{1,9})?([0-9][0-9]{6})$/)) {
      setIsFormValid3(false);
      setErrorMessage3("Invalid mobile number format");
    } else {
      setIsFormValid3(true);
      setErrorMessage3("");
    }
  };

  const handleFirstChange = (event) => {
    const value = event.target.value;
    setFirstName(value);

    // validate email format
    if (!value.match(/^[a-zA-Z]{0,2000}$/)) {
      setIsFormValid(false);
      setErrorMessage("Special charecters and number are not allowed");
    } else if(value.length <= 0) {
      setIsFormValid(false);
      setErrorMessage("Minimum 1 letters required");
    } else {
      setIsFormValid(true);
      setErrorMessage("");
    }
  };

  const handlelastChange = (event) => {
    const value = event.target.value;
    setLastName(value);

    // validate email format
    if (!value.match(/^[a-zA-Z]{0,2000}$/)) {
      setIsFormValid1(false);
      setErrorMessage1("Special charecters and number are not allowed");
    } else if(value.length <= 0) {
      setIsFormValid1(false);
      setErrorMessage1("Minimum 1 letters required");
    } else {
      setIsFormValid1(true);
      setErrorMessage1("");
    }
  };

  const handleMessageChange = (event) => {
    const value = event.target.value;
    setMessage(value);

    // validate email format
    if (!value.match(/^[a-zA-Z]{0,2000}$/)) { 
      setIsFormValid4(false);
      setErrorMessage4("Special charecters and number are not allowed");
      // setErrorMessage4("Message input required");
    } else if(value.length <= 0) {
      setIsFormValid4(false);
      setErrorMessage4("Minimum 1 letters required");
    } else {
      setIsFormValid4(true);
      setErrorMessage4("");
    }
  };

  const password = CryptoJS.enc.Utf8.parse("7819461082573529");

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      firstName: firstName
        ? CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(firstName), password, {
            keySize: 128 / 8,
            iv: password,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString()
        : "",
      lastName: lastName
        ? CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(lastName), password, {
            keySize: 128 / 8,
            iv: password,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString()
        : "",
      phoneNumber: phoneNumber
        ? CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(phoneNumber), password, {
            keySize: 128 / 8,
            iv: password,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString()
        : "",
      countryCode: countryCode
        ? CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(`+${countryCode}`),
            password,
            {
              keySize: 128 / 8,
              iv: password,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString()
        : "+44",
      email: emailId
        ? CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(emailId), password, {
            keySize: 128 / 8,
            iv: password,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString()
        : "",
      // password: password,
      message: message
        ? CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message), password, {
            keySize: 128 / 8,
            iv: password,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString()
        : "",
      isAcceptedTermsAndCondtions: checked,
    };
    if (payload) {
      if (firstName && lastName && phoneNumber && emailId && message) {
        if (checked === true) {
          if (isFormValid === true && isFormValid1 === true && isFormValid2 === true && isFormValid3 === true && isFormValid4 === true) {
            setIsShowLoader(true);
            ForDomiciliaryServices((res) => {
              const { message, statusCode } = res;
              if (statusCode === 200) {
                setIsShowLoader(false);
                setShowConfirmModal(true);
                setShowConfirmModal1(true);
                setAlertText(
                  "Thank you for registering, admin will  notify you once your account is approved"
                );
                // window.location.reload();
              } else if (statusCode === 400) {
                setShowConfirmModal(false);
                setShowConfirmModal1(true);
                setIsShowLoader(false);
                setAlertText(message);
              } else {
                setShowConfirmModal(false);
                setShowConfirmModal1(true);
                setIsShowLoader(false);
                setAlertText(message);
              }
            }, payload);
          } else {
            setShowConfirmModal1(true);
            setShowConfirmModal(false);
            setIsShowLoader(false);
            setAlertText(
              "Please enter valid data"
            );
          }
        } else {
          setShowConfirmModal1(true);
          setShowConfirmModal(false);
          setIsShowLoader(false);
          setAlertText("Please agree the terms of service and privacy policy");
        }
      } else {
        setShowConfirmModal(false);
        setShowConfirmModal1(true);
        setIsShowLoader(false);
        setAlertText("Fill all the required fields");
      }
    }
  };

  const handleConfirm = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const getFlag = () => {
    return <Flag />;
    return null;
  };

  // const handleCountryCodeChange = (value) => {
  //   setCountryCode(value);
  // };

  // const handlePhoneNumberChange = (value) => {
  //   setPhoneNumber(value);
  // };

  return (
    <div className="service">
      <div className="service-provider">
        <div
          className="left"
          style={{
            backgroundImage: `url(${service})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
          }}
        ></div>
        <MDBContainer className="service-page gradient-form">
          <MDBRow className="service-container">
            <MDBCol className="mb-5 service-left-main">
              <div className="d-flex flex-column service-center">
                <div className="service-logo"></div>
                <Slide bottom>
                  <h2>Please register to start booking services</h2>
                </Slide>
                <Slide bottom>
                  {/* <p>
                    Use our on-demand services mobile app to get 24/7 support{" "}
                  </p> */}
                </Slide>
                <div className="form__input">
                  <Slide bottom>
                    <label>First name *</label>
                    <input
                      placeholder="First name *"
                      type="name"
                      onChange={(e) => handleFirstChange(e)}
                    />
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                  </Slide>
                </div>
                <div className="form__input">
                  <Slide bottom>
                    <label>Last name *</label>
                    <input
                      placeholder="Last name *"
                      type="name"
                      onChange={(e) => handlelastChange(e)}
                    />
                    {errorMessage1 && (
                      <p style={{ color: "red" }}>{errorMessage1}</p>
                    )}
                  </Slide>
                </div>
                <div className="form__input">
                  <Slide bottom>
                    <label>Email Address *</label>
                    <input
                      placeholder="Email Address *"
                      type="email"
                      onChange={(e) => handleEmailChange(e)}
                    />
                    {errorMessage2 && (
                      <p style={{ color: "red" }}>{errorMessage2}</p>
                    )}
                  </Slide>
                </div>
                <div className="form__input num">
                  <label>Phone Number *</label>
                  <div className="form__input__inputs">
                    <PhoneInput
                      country={"gb"}
                      placeholder="Country Code"
                      value={countryCode}
                      onChange={setCountryCode}
                      maxLength={3}
                      countryComponent={getFlag()}
                      readOnly={true}
                    />
                    <Slide bottom>
                      <input
                        placeholder="Phone Number *"
                        maxLength={15}
                        onChange={(e) => handlePhoneChange(e)}
                        inputMode="numeric"
                      />
                    </Slide>
                  </div>
                  {errorMessage3 && (
                    <p style={{ color: "red" }}>{errorMessage3}</p>
                  )}
                </div>
                <div className="form__input">
                  <label>Please state below the services you require *</label>
                  <textarea
                    rows={4}
                    placeholder=""
                    value={message}
                    onChange={(e) => handleMessageChange(e)}
                  />
                  {errorMessage4 && (
                    <p style={{ color: "red" }}>{errorMessage4}</p>
                  )}
                </div>
                {/* <div className='form__input'>
                                    <Slide bottom>
                                        <label>Password *</label>
                                        <input placeholder='Password *' type={`${isToggle ? 'text' : 'password'}`}
                                            required onChange={(e) => setPassword(e.target.value)} />
                                        <span
                                            role='button'
                                            tabIndex={0}
                                            className={`${isToggle
                                                ? 'fa fa-eye-slash service__icon'
                                                : 'fa fa-eye service__icon'
                                                }`}
                                            onClick={() => {
                                                handleToggle();
                                            }}
                                            onKeyDown={() => {
                                                handleToggle();
                                            }}
                                        ></span>
                                    </Slide>
                                </div> */}

                <div className="form__terms">
                  <Checkbox
                    // type="checkbox"
                    // defaultValue={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <p>
                    I have read and agreed to the{" "}
                    <a href="https://yozoi.com/terms" target="_blank">
                      Terms of service
                    </a>{" "}
                    and{" "}
                    <a href="https://yozoi.com/privacy-policy" target="_blank">
                      Privacy policy
                    </a>
                    .
                  </p>
                </div>
                <div className="service-submit pt-1 pb-1">
                  <Slide bottom>
                    <button
                      className="submit-button"
                      // disabled={!isFormValid}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </Slide>
                  {/* <a className="text-muted" href="#!">Forgot password?</a> */}
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      {showConfirmModal && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={alertText}
          onConfirm={() => {
            handleConfirm();
          }}
          onCancel={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      {showConfirmModal1 && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={alertText}
          onConfirm={() => {
            setShowConfirmModal1(false);
          }}
          onCancel={() => {
            setShowConfirmModal1(false);
          }}
        />
      )}
      {isShowLoader ? <Loader /> : null}
    </div>
  );
};

export default ServiceProvider;
