import { fetchCall, fetchNoCall, fetchLoginCall } from './ajax';
import { API_CONSTANTS, API_METHODS } from './api-constants';


export const ForDomiciliaryServices = (callback, payload) => {
    const url = `${API_CONSTANTS.DOMICILIARY_SERVICES}`;
    return fetchCall((response) => {
        callback(response);
    }, url, API_METHODS.POST, payload);
};

export const ForServiceprovider = (callback, payload) => {
    const url = `${API_CONSTANTS.SERVICE_PROVIDER}`;
    return fetchCall((response) => {
        callback(response);
    }, url, API_METHODS.POST, payload);
};

export const SignUpForServiceProviderWithOtp = (callback, payload) => {
    const url = `${API_CONSTANTS.SERVICE_PROVIDER_OTP}`;
    return fetchCall((response) => {
        callback(response);
    }, url, API_METHODS.POST, payload);
};

export const RegisterServiceProvider = (callback, payload) => {
    // const queryString = `?FirstName=${payload.FirstName}&FirstName=${payload.FirstName}&Email=${payload.Email}&Document=${Document}`
    const url = `${API_CONSTANTS.REGISTER_SERVICE_PROVIDER}`;
    return fetchCall((response) => {
        callback(response);
    }, url, API_METHODS.POST, payload);
};

export const SelectServicesList = (callback, payload) => {
    const url = `${API_CONSTANTS.SELECT_SERVICES_DROPDOWN}`;
    return fetchCall((response) => {
        callback(response);
    }, url, API_METHODS.GET, payload);
};

export const GetCountryList = (callback, payload) => {
    const url = `${API_CONSTANTS.SELECT_COUNTRY_DROPDOWN}`;
    return fetchCall((response) => {
        callback(response);
    }, url, API_METHODS.GET, payload);
};