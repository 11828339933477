import React from 'react';
import './HowYozoiWorksStyles.scss';
import { Helmet } from 'react-helmet-async';
// import banner from '../../assets/images/about.jpg';
import banner from '../../assets/images/how yozoi works/banner.jpg';
import vision1 from '../../assets/images/how yozoi works/how yozoi 1.png';
import vision2 from '../../assets/images/how yozoi works/how yozoi.png';
import Slide from 'react-reveal/Slide';

const HowYozoiWorksComponent = () => {
    return (
        <>
            <Helmet>
                <title>Yozoi | How we work</title>
                <meta name='description' content='Yozoi | How we work' />
                <meta name='keyWords' content='Yozoi | How we work' />
            </Helmet>
            <div className='how'>
                <div className='how__container'>
                    <div className='how__banner' style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>How we work</h2>
                        </Slide>
                    </div>
                    <div className='how__section'>
                        <div className='how__section__heading'>
                            {/* <Slide bottom cascade>
                                <h2>How we work</h2>
                            </Slide> */}
                            {/* <div className='how__section__line'></div> */}
                        </div>
                        <div className='how__sections'>
                            <div className='how__sections__left'>
                                <div className='how__sections__left__image'>
                                    <Slide left>
                                        <img src={vision1} alt='vision1' />
                                    </Slide>
                                </div>
                                <Slide left>
                                    <img src={vision2} alt='vision2' className='vision2' />
                                </Slide>
                            </div>
                            <div className='how__sections__right'>
                                <div className='how__sections__right__content'>
                                    <Slide right>
                                        <h4><span>Our vision</span> is “To empower people to fulfil the domiciliary service needs of their societies”</h4>
                                        <p>Our in-development mobile app shall be used by customers to request services and by service providers to deliver services. The services shall be  available 24/7 and the app will be easy to operate in simple steps as shown below:</p>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='how__steps'>
                        <div className='how__steps__container'>
                            <div className='how__steps__heading'>
                                <Slide bottom cascade>
                                    <h2>Steps to follow</h2>
                                </Slide>
                                <div className='how__steps__line'></div>
                            </div>
                            <hr />
                            <div className='how__steps__section'>
                                <div className='how__steps__section__line'></div>
                                <div className='how__steps__sections'>
                                    <div className='how__steps__section__step'>
                                        <div className='how__steps__section__step__left'>
                                            <Slide left>
                                                <h4>Step 1</h4>
                                                {/* <h3>01</h3> */}
                                            </Slide>
                                        </div>
                                        <div className='how__steps__section__step__center'>
                                            <div className='how__steps__section__step__center__dot'></div>
                                        </div>
                                        <div className='how__steps__section__step__right'>
                                            <Slide right>
                                                {/* <div className='how__steps__section__step__right__num'>
                                                    <h3>01</h3>
                                                </div> */}
                                                <div className='how__steps__section__step__right__content'>
                                                    <p>Customers and service providers complete the registration process either on the mobile app or the website. This enables Yozoi to fully understand customer’s requirements and service provider’s capabilities and match service requests with the appropriate service provider. The registration process has embedded technologies for security screening of the registrants.</p>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='how__steps__section__step'>
                                        <div className='how__steps__section__step__left'>
                                            <Slide right>
                                                <h4 className='tas'>Step 2</h4>
                                                {/* <h3>02</h3> */}
                                            </Slide>
                                        </div>
                                        <div className='how__steps__section__step__center'>
                                            <div className='how__steps__section__step__center__dot'></div>
                                        </div>
                                        <div className='how__steps__section__step__right rev'>
                                            <Slide left>
                                                {/* <div className='how__steps__section__step__right__num'>
                                                    <h3>02</h3>
                                                </div> */}
                                                <div className='how__steps__section__step__right__content'>
                                                    <p>Customer opens the app, selects a service, and requests delivery either immediately or on a future date and time.</p>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='how__steps__section__step'>
                                        <div className='how__steps__section__step__left'>
                                            <Slide left>
                                                <h4>Step 3</h4>
                                                {/* <h3>03</h3> */}
                                            </Slide>
                                        </div>
                                        <div className='how__steps__section__step__center'>
                                            <div className='how__steps__section__step__center__dot'></div>
                                        </div>
                                        <div className='how__steps__section__step__right'>
                                            <Slide right>
                                                {/* <div className='how__steps__section__step__right__num'>
                                                    <h3>03</h3>
                                                </div> */}
                                                <div className='how__steps__section__step__right__content'>
                                                    <p>The customer’s service request becomes visible to the service providers selected by the app. A service provider chooses to confirm that he/she will deliver the service. The customer sees the service provider’s profile and decides whether to accept the service offer or request an alternative.</p>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='how__steps__section__step'>
                                        <div className='how__steps__section__step__left'>
                                            <Slide right>
                                                <h4 className='tas'>Step 4</h4>
                                                {/* <h3>04</h3> */}
                                            </Slide>
                                        </div>
                                        <div className='how__steps__section__step__center'>
                                            <div className='how__steps__section__step__center__dot'></div>
                                        </div>
                                        <div className='how__steps__section__step__right rev'>
                                            <Slide left>
                                                {/* <div className='how__steps__section__step__right__num'>
                                                    <h3>04</h3>
                                                </div> */}
                                                <div className='how__steps__section__step__right__content'>
                                                    <p>Once the service is booked, Yozoi app tracks the service providers journey to the customer, entry into the customer’s house, monitors the service’s progress and determines its end.</p>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='how__steps__section__step'>
                                        <div className='how__steps__section__step__left'>
                                            <Slide left>
                                                <h4>Step 5</h4>
                                                {/* <h3>05</h3> */}
                                            </Slide>
                                        </div>
                                        <div className='how__steps__section__step__center'>
                                            <div className='how__steps__section__step__center__dot'></div>
                                        </div>
                                        <div className='how__steps__section__step__right'>
                                            <Slide right>
                                                {/* <div className='how__steps__section__step__right__num'>
                                                    <h3>05</h3>
                                                </div> */}
                                                <div className='how__steps__section__step__right__content'>
                                                    <p>The customer and service provider rate each other. Additionally, Yozoi prepares a service report which is saved in the customer account’s database. This can be accessed at any time by the customer or the customer’s nominated family members or friends. The service reports can be modified by the service providers; however, each modified copy and the original are saved and the original can never be deleted. In this way, Yozoi keeps full provenance and transparency of every service delivered to a customer as well as a record the customer’s wellbeing history.</p>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='how__steps__section__step'>
                                        <div className='how__steps__section__step__left'>
                                            <Slide right>
                                                <h4 className='tas'>Step 6</h4>
                                                {/* <h3>06</h3> */}
                                            </Slide>
                                        </div>
                                        <div className='how__steps__section__step__center'>
                                            <div className='how__steps__section__step__center__dot'></div>
                                        </div>
                                        <div className='how__steps__section__step__right rev'>
                                            <Slide left>
                                                {/* <div className='how__steps__section__step__right__num'>
                                                    <h3>06</h3>
                                                </div> */}
                                                <div className='how__steps__section__step__right__content'>
                                                    <p>The customer may request installation of service enhancing and personalised technologies in his/her home. For example: keyless entry, movement analytics, life pattern analytics, vital life signs monitoring and other technology systems. These wireless networked technologies do not require any action on part of the customer and, yet they enable Yozoi to continuously monitor and assess the service, the customer’s well-being and requirements in real-time. Using data analytics and AI technology, Yozoi can automate services provided, documentation and reporting as well as recommending service plans and making interventions to ensure optimum well-being of the customers.</p>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowYozoiWorksComponent;