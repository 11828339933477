import React from 'react';
import './AwareHomeServices.scss';
import { Helmet } from 'react-helmet-async';
import banner from '../../assets/images/service-page.jpg';
import Accidentsecurity from '../../assets/images/aware/Accident security1.png';
import intusiondetection from '../../assets/images/aware/intusion detection1.png';
import healthsafetysecurity from '../../assets/images/aware/health safety security.png';
import wellbeingsecurity from '../../assets/images/aware/wellbeing security.png';
import Slide from 'react-reveal/Slide';


const AwareHomeServices = () => {
  return (
    <>
    <Helmet>
        <title>Yozoi | Aware Home Services</title>
        <meta name='description' content='Yozoi | Aware Home Services' />
        <meta name='keyWords' content='Yozoi | Aware Home Services' />
    </Helmet>
    <div className='aware'>
        <div className='aware__container'>
            <div className='aware__banner' style={{
                backgroundImage: `url(${banner})`,
                backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top'
            }}>
                <Slide left>
                    <h2>Services</h2>
                </Slide>
            </div>
            <div className='aware__sections'>
                <div className='aware__section'>
                    <div className='aware__section__heading'>
                        <Slide bottom cascade>
                            <h2>Aware Home Services</h2>
                        </Slide>
                        <div className='aware__section__line'></div>
                        <Slide bottom>
                            <p>Yozoi Aware Home System (AHS) comprises state-of-the art non-imaging sensors customised for specific needs of clients. The AHS collects data 24/7 which is securely saved on a cloud data base. The data is continuously analysed, enabling Yozoi artificial intelligence (AI) to learn about the clients and detect or predict issues before they become emergencies. Upon client’s request, our experts can assess the client’s home environment and discuss requirements to design a customised AHS for the client. The data analytics, AI reports and alerts can be accessed by the client or nominated family members or friends via the Yozoi app.</p>
                            <h6>Contact us to arrange an assessment of your requirements,</h6>
                        </Slide>
                    </div>
                    <div className='aware__section__main'>
                        <div className='aware__section__container' id='accident-detection'>
                            <div className='aware__section__container__left'>
                                <div className='aware__section__container__left__image'>
                                    <Slide left>
                                        <img src={Accidentsecurity} alt='Accident detection' />
                                    </Slide>
                                </div>
                            </div>
                            <div className='aware__section__container__right'>
                                <div className='aware__section__container__right__content'>
                                    <Slide right>
                                        <h3>Accident detection</h3>
                                        <p>The Yozoi Aware Home System (AHS) installed in the client’s house, does not require the client to wear any devices or take any actions. The AWS operates independently with minimal power requirements, continuously detecting a client’s position, orientation and movements and sending data to the Yozoi cloud. The data analytics and AI components of the Yozoi technical platform compare the data patterns to the client’s previously recorded normal activity patterns and pre-set parameters. This enables the AI to provide alerts if it concludes that the client has fallen down or is in an abnormal situation. According to pre-agreed protocols, if the customer does not respond to an alert on Yozoi app, a service provider is dispatched to the customer or the emergency services alerted.</p>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                        <div className='aware__section__container' id='intrusion-detection'>
                            <div className='aware__section__container__left'>
                                <div className='aware__section__container__left__image'>
                                    <Slide right>
                                        <img src={intusiondetection} alt='Intrusion detection' />
                                    </Slide>
                                </div>
                            </div>
                            <div className='aware__section__container__right'>
                                <div className='aware__section__container__right__content'>
                                    <Slide left>
                                        <h3>Intrusion detection</h3>
                                        <p>Our Aware Home System (AHS) can learn the normal occupancy patterns of the client’s house as a function of time. Any deviations from the normal patterns are detected and an alert is sounded on the client’s Yozoi app. According to pre-agreed protocols, if the customer does not respond to an alert on Yozoi app, a service provider is dispatched to the customer or the emergency services alerted.</p>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                        <div className='aware__section__container' id='wellbeing-security'>
                            <div className='aware__section__container__left'>
                                <div className='aware__section__container__left__image'>
                                    <Slide left>
                                        <img src={wellbeingsecurity} alt='Wellbeing security' />
                                    </Slide>
                                </div>
                            </div>
                            <div className='aware__section__container__right'>
                                <div className='aware__section__container__right__content'>
                                    <Slide right>
                                        <h3>Wellbeing security</h3>
                                        <p>The Yozoi Aware Home System (AHS) can be designed to continuously monitor and collect data on the client’s health parameters and activity patterns. The data saved on Yozoi cloud is continuously analysed and used by Yozoi AI to predict the client’s normal wellbeing pattern. Deviations from the normal wellbeing pattern are analysed to predict wellbeing issues. According to protocols agreed with the client, if issues are predicted then Yozoi can make interventions and strive to prevent the predicted issues from becoming emergencies. The interventions can include issuing advisory alerts to the client or their nominated friend or family proxy, initiating home visit services for the client and/or alerting medical services.</p>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                        {/* <div className='aware__section__container' id='social-activities'>
                            <div className='aware__section__container__left'>
                                <div className='aware__section__container__left__image'>
                                    <Slide right>
                                        <img src={social} alt='Social activities' />
                                    </Slide>
                                </div>
                            </div>
                            <div className='aware__section__container__right'>
                                <div className='aware__section__container__right__content'>
                                    <Slide left>
                                        <h3>Social activities</h3>
                                        <p>We can enable you to engage in social activities with matched service providers either at home or outside. For example, home visits for a cup of tea and chat or playing board games and outings to cinemas and theatres.</p>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                        <div className='aware__section__container' id='visits-to-medics'>
                            <div className='aware__section__container__left'>
                                <div className='aware__section__container__left__image'>
                                    <Slide left>
                                        <img src={visittomedics} alt='Visits to medics' />
                                    </Slide>
                                </div>
                            </div>
                            <div className='aware__section__container__right'>
                                <div className='aware__section__container__right__content'>
                                    <Slide right>
                                        <h3>Visits to medics</h3>
                                        <p>Do you need help to visit the doctor, dentist or pharmacy? Just use the Yozoi app to request a service provider to provide transport or escort.</p>
                                    </Slide>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
  )
}

export default AwareHomeServices;