import React from 'react';
import './footer.scss';
import logo from '../../assets/images/Yozoi.png';
import { HashLink } from 'react-router-hash-link';
import { Link, NavLink } from 'react-router-dom';
import googleplay from '../../assets/images/google play.png';
import appleplay from '../../assets/images/app Store.png';
import Tooltip from '@mui/material/Tooltip';

const Footer = () => {
    const address = 'Yozoi Limited, 124 City Road, London EC4V 2NX, United Kingdom';

    const handleClickScroll = () => {
        window.scrollTo(0, 0);
    };

    // const handleLinkClick = (event) => {
    //     event.preventDefault();
    //     window.location.href = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    // };


    return (
        <div className='footer'>
            <div className='footer__container'>
                <div className='footer__sections'>
                    <div className='footer__left'>
                        <div className='footer__left__heading'>
                            <h3>Learn more</h3>
                        </div>
                        <div className='footer__left__links'>
                            <ul>
                                <li onClick={handleClickScroll}><NavLink to='/about-us'>About us</NavLink></li>
                                <li onClick={handleClickScroll}><NavLink to='/terms'>Terms of service</NavLink></li>
                                <li onClick={handleClickScroll}><NavLink to='/privacy-policy'>Privacy policy</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className='footer__center'>
                        <div className='footer__center__heading'>
                            <h3>Stay connected</h3>
                        </div>
                        <div className='footer__center__links'>
                            <ul>
                                <li><a href="https://www.facebook.com/yozoicare" target='_blank'><i className='fa fa-facebook' /></a></li>
                                <li><a href='https://www.linkedin.com/company/yozoi/?viewAsMember=true' target='_blank'><i className='fa fa-linkedin' /></a></li>
                                <li><HashLink><i className='fa fa-youtube' /></HashLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className='footer__right'>
                        <div className='footer__right__heading'>
                            <h3>Contact us</h3>
                        </div>
                        <div className='footer__right__details'>
                            {/* <div className='footer__right__details__content'>
                                <a href='tel:07919 200462'>
                                    <i className='fa fa-phone' />
                                    <p>07919 200462</p>
                                    <p>Yozoi phone number (to be provided)</p>
                                </a>
                            </div> */}
                            <div className='footer__right__details__content'>
                                <a href='mailto:info@yozoi.com'>
                                    <i className='fa fa-envelope' />
                                    <p>info@yozoi.com</p>
                                </a>
                            </div>
                            <div className='footer__right__details__content'>
                                <a href={`https://www.google.com/maps?q=${encodeURIComponent(address)}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <i className='fa fa-location-arrow' />
                                    <p>Yozoi Limited <br />
                                        124 City Road <br />
                                        London EC4V 2NX <br />
                                        United Kingdom</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer__bottom'>
                    <div className='footer__bottom__logo'>
                        <img src={logo} alt='footer-logo' />
                        {/* <h2>Yozoi</h2> */}
                    </div>
                    <h5>Get it on</h5>
                    <div className='footer__bottom__store'>
                        <Tooltip title='coming soon, please register and send message to users@yozoi.com if you need assistance' placement='top'>
                            <img src={googleplay} alt='' />
                        </Tooltip>
                        <Tooltip title='coming soon, please register and send message to users@yozoi.com if you need assistance' placement='top'>
                            <img src={appleplay} alt='' />
                        </Tooltip>
                    </div>
                    <p>&#169; 2023 Yozoi Limited</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;