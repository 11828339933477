// import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './pages/header/header';
import Home from './pages/home/home';
import Footer from './pages/footer/footer';
import AboutUs from './pages/about-us/aboutus';
import Registration from './pages/registration/registration';
import Service from './pages/registration/service/service';
import PrivacyPolicyComponent from './pages/privacy-policy/PrivacyPolicyComponent';
import TermsAndConditionsComponent from './pages/terms-and-conditions/TermsAndConditionsComponent';
import WhatWeDoComponent from './pages/what-we-do/WhatWeDoComponent';
import HowYozoiWorksComponent from './pages/how-yozoi-works/HowYozoiWorksComponent'
import WhyWorkComponents from './pages/why-work/WhyWorkComponents';
import TeamComponent from './pages/team/TeamComponent';
import SafetyComponent from './pages/safety/SafetyComponent';
import HomeVisits from './pages/home-visits/HomeVisits';
import CompanionshipComponent from './pages/companionship/CompanionshipComponent';
import DemoValidate from './pages/registration/demo-validate/DemoValidate';
import FillDetails from './pages/registration/filldetails/FillDetails';
import AboutYozoi from './pages/aboutYozoi/AboutYozoi';
import AwareHomeServices from './pages/awarehomeservices/AwareHomeServices';
import ChildCare from './pages/child-care/ChildCare';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home title='Yozoi | Home' />} />
        <Route path='/about-us' element={<AboutUs title='Yozoi | About Us' />} />
        <Route path='/learn-about-yozoi-services' element={<AboutYozoi title='Yozoi | Learn About Yozoi Services' />} />
        <Route path='/registration' element={<Registration title='Yozoi | Registration' />} />
        <Route path='/registration/service' element={<Service title='Yozoi | Book a Service' />} />
        <Route path='/privacy-policy' element={<PrivacyPolicyComponent title='Yozoi | Privacy Policy' />} />
        <Route path='/terms' element={<TermsAndConditionsComponent title='Yozoi | Terms and Conditions' />} />
        <Route path='/our-technology' element={<WhatWeDoComponent title='Yozoi | Our Technology' />} />
        <Route path='/how-we-work' element={<HowYozoiWorksComponent title='Yozoi | How We Work' />} />
        <Route path='/why-work-at-yozoi' element={<WhyWorkComponents title='Yozoi | Why Work At Yozoi' />} />
        <Route path='/team' element={<TeamComponent title='Yozoi | Team' />} />
        <Route path='/safety' element={<SafetyComponent title='Yozoi | Safety' />} />
        <Route path='/home-visits' element={<HomeVisits title='Yozoi | Home Visits' />} />
        <Route path='/companionship' element={<CompanionshipComponent title='Yozoi | Companionship' />} />
        <Route path='/aware-home-system' element={<AwareHomeServices title='Yozoi | Aware Home System' />} />
        <Route path='/ChildCare' element={<ChildCare title='Yozoi | Child Care' />} />
        <Route path='/demo' element={<DemoValidate title='Yozoi | Companionship' />} />
        {/* <Route path='/new' element={<RegistrationNew title='Yozoi | Registration' />} /> */}
        <Route path='/fill' element={<FillDetails title='Yozoi | Registration' />} />
        {/* <Route path='/fill:email' element={<FillDetails title='Yozoi | Registration' />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
