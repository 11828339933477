import React from 'react';
import './ChildCare.scss';
import { Helmet } from 'react-helmet-async';
import banner from '../../assets/images/child care/yozoi/Services-min.jpg';
import Philosophy from '../../assets/images/child care/yozoi/philosophy.png'
import SchoolPick from '../../assets/images/child care/yozoi/School pic ups.png'
import Homecare from '../../assets/images/child care/yozoi/home care visits.png'

import Activities from '../../assets/images/child care/yozoi/Activities.png'
import Slide from 'react-reveal/Slide';

const ChildCare = () => {
  return (
    <>
     <Helmet>
                <title>Yozoi | Child Care</title>
                <meta name='description' content='Yozoi | Home Visits' />
                <meta name='keyWords' content='Yozoi | Home Visits' />
            </Helmet>
            <div className='visits'>
                <div className='visits__container'>
                    <div className='visits__banner' style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>Services</h2>
                        </Slide>
                    </div>
                    <div className='visits__sections'>
                        <div className='visits__section'>
                            <div className='visits__section__heading'>
                                <Slide bottom cascade>
                                    <h2>Child Care</h2>
                                </Slide>
                                <div className='visits__section__line'></div>
                            </div>
                            <div className='visits__section__main'>
                                <div className='visits__section__container' id='Philosophy'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={Philosophy} alt='Bedding' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>                        <Slide right>
                                            {/* <h3>Philosophy</h3>
                                            <p>Change of bedding in the customer’s home with fresh bedding provided by the customer. Please also request laundry service if you require bedding to be laundered and dried either in your home or taken away and delivered back after laundering.</p> */}

                                            <h3>Childcare</h3>
                                            <p>All children are different and we treat kids with a tailored approach, discussed and agreed with parents. We help children gain confidence and develop their learning and communication skills via a nurturing, patient, and gentle approach. All staff are experienced, and/or have underdone training such as special educational needs training. Furthermore, all staff are insured and checked through enhanced DBS and employer references. The staff level of skill and experience is bespoke and varies with service requirements defined by parents. We also offer the option of live monitoring of the service via an electronic device worn by the carer. Our services include:</p>
                                        </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='School-Pick-ups-and-care'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={SchoolPick} alt='Cleaning ' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                {/* <h3>School pick-ups and care </h3>
                                                <p>Our service providers can clean all in-door areas of your home including vacuuming, mopping, dusting, and polishing. Outdoor cleaning services are also available including patios, driveways, and gardens.</p> */}

                                                <h3>After school pick up, home care and activities. Weekdays 15.00 to 19.30 Hrs</h3>
                                                <p>A dedicated member of our carer staff collects your child from school and takes them home. The carer stays with the child, taking general care including provision of meals and helping with homework or engaging in activities. The activities can include reading, writing, drawing, computing, learning board games, story-telling and learning about cinema and cinematic aspects. The service approach, duration and pricing are bespoke and as required by parents. Please call us to arrange a discussion meeting.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='Home-care-visits'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={Homecare} alt='Footcare ' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide right>
                                                {/* <h3>Home care visits </h3>
                                                <p>Cutting nails, hydrating, and massaging feet for improved circulation.</p> */}

                                                <h3>Home care and activities, available 24/7 </h3>
                                                <p>As above but excluding school pick up. This service mode includes visits to parks, museums, shopping centres, cinema, and the GP. The service approach, duration and pricing are bespoke, subject to service requirements and agreement with parents. Please call us to arrange a discussion meeting.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='Activities'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={Activities} alt='Gardening' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                <h3>Activities</h3>
                                                <p>All routine garden maintenance work such as cutting grass, trimming hedges and plants, and clearing garden waste.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    </>
  )
}

export default ChildCare
