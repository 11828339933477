import React from 'react';
import './WhatWeDoStyles.scss';
import { Helmet } from 'react-helmet-async';
// import banner from '../../assets/images/about.jpg';
import banner from '../../assets/images/company/technology.jpg';
import tech from '../../assets/images/company/our technology.png';
import techicon from '../../assets/images/company/technology icon.svg';
import ai from '../../assets/images/company/ai.png';
import aiicon from '../../assets/images/company/ai icon.svg';
import arrow1 from '../../assets/images/company/arrow 1.png';
import safety from '../../assets/images/company/safety-1.png';
import safetyicon from '../../assets/images/company/safety icon.svg';
import arrow2 from '../../assets/images/company/arrow 2.png';
import keyless from '../../assets/images/company/keyless entry.png';
import card1 from '../../assets/images/company/Service tracking.png';
import card2 from '../../assets/images/company/Personalised service plans.png';
import card3 from '../../assets/images/company/Customer health.png';
import card4 from '../../assets/images/company/Family access.png';
import card5 from '../../assets/images/company/Service provider ratings.png';
import card6 from '../../assets/images/company/Service provider training.png';
import card7 from '../../assets/images/company/Reporting.png';
import card8 from '../../assets/images/company/Payment automation.png';
import ReadMoreReact from 'read-more-react';
import Slide from 'react-reveal/Slide';

const WhatWeDoComponent = () => {
    return (
        <>
            <Helmet>
                <title>Yozoi | Our Technology</title>
                <meta name='description' content='Yozoi | Our Technology' />
                <meta name='keyWords' content='Yozoi | Our Technology' />
            </Helmet>
            <div className='we'>
                <div className='we__container'>
                    <div className='we__banner' style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>Our technology</h2>
                        </Slide>
                    </div>
                    <div className='we__sections'>
                        <div className='we__section'>
                            <div className='we__section__what'>
                                <div className='we__section__what__heading'>
                                    {/* <Slide bottom cascade>
                                        <h2>Our technology ?</h2>
                                    </Slide> */}
                                    {/* <div className='we__section__what__line'></div> */}
                                </div>
                                <div className='we__section__what__section'>
                                    <div className='we__section__what__left'>
                                        <div className='we__section__what__left__content'>
                                            <Slide left>
                                                {/* <h3>Our Technology</h3> */}
                                                <p>Yozoi’s is developing a services model based on a mobile applications platform comprising a deep technology back-end working in tandem with a human front-end. We believe that such a hybrid model can fulfil the domiciliary support services needs of modern societies. </p>
                                                <p>We aim to continuously improving and developing our mobile applications platform in line with the latest developments in sensors, IoT, big data and artificial intelligence technology. The current target features of the Yozoi platform are described below.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='we__section__what__right'>
                                        <div className='we__section__what__right__section'>
                                            <Slide right>
                                                <div className='we__section__what__right__section__tab'>
                                                    {/* <h4>Technology</h4> */}
                                                </div>
                                                <div className='we__section__what__right__section__image'>
                                                    <img src={tech} alt='Technology' />
                                                </div>
                                                <div className='we__section__what__right__section__card'>
                                                    <img src={techicon} alt='Technology_icon' />
                                                    <h5>Our Technology</h5>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='we__section'>
                            <div className='we__section__mobile'>
                                <div className='we__section__mobile__section'>
                                    <div className='we__section__mobile__left'>
                                        <div className='we__section__mobile__left__content'>
                                            <Slide right>
                                                <h3>AI driven mobile app</h3>
                                                <p>Yozoi mobile app platform is where service transactions are initiated, monitored, delivered and payments processed. The app has the capability to continuously collect data using a combination of methods such as smart phone sensors, service provider reporting and customised sensor technologies installed in the customer’s home in accord with the customer’s personal requirements and consent. This leads to what we call an Aware Home environment where the customer’s normal pattern of life is determined based on data analytics and AI, enabling early prediction of health and wellbeing issues.  The predictive AI provides opportunities for early interventions for optimal wellbeing of customers and prevention of health and wellbeing emergencies.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='we__section__mobile__right'>
                                        <div className='we__section__mobile__right__section'>
                                            {/* <Slide bottom>
                                                <img src={arrow1} alt='arrow 1' className='arrow-1' />
                                            </Slide> */}
                                            <Slide left>
                                                <div className='we__section__mobile__right__section__tab'>
                                                    {/* <h4>Technology</h4> */}
                                                </div>
                                                <div className='we__section__mobile__right__section__image'>
                                                    <img src={ai} alt='AI driven mobile app' />
                                                </div>
                                                <div className='we__section__mobile__right__section__card'>
                                                    <img src={aiicon} alt='AI driven mobile app__icon' />
                                                    <h5>AI mobile app</h5>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='we__section'>
                            <div className='we__section__safe'>
                                <div className='we__section__safe__section'>
                                    <div className='we__section__safe__left'>
                                        <div className='we__section__safe__left__content'>
                                            <Slide left>
                                                <h3>Safety & security</h3>
                                                <p>Yozoi app compares service providers face pictures in real-time against national identity documents such as passports to verify identity. Next, the app verifies residential addresses against utility bills and bank account statements. The app also checks national databases for any criminal history, for example in UK,  we check DBS certificates, criminal convictions and credit rating. After registration of service providers, the app automatically conducts criminal checks on a regular basis for the detection of any emerging criminality. The customers are also subjected to a similar procedure of checking identity, addresses and any evidence of criminality or insolvency. Any service providers or customers failing such tests are not permitted to join Yozoi. Any service providers or customers who are members of Yozoi and fail any of the regular security checks, will have their membership cancelled. By applying such stringent procedures, we ensure that customers and service providers can operate in a safe and secure manner.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='we__section__safe__right'>
                                        <div className='we__section__safe__right__section'>
                                            {/* <Slide bottom>
                                                <img src={arrow2} alt='arrow 1' className='arrow-2' />
                                            </Slide> */}
                                            <Slide right>
                                                <div className='we__section__safe__right__section__tab'>
                                                    {/* <h4>Technology</h4> */}
                                                </div>
                                                <div className='we__section__safe__right__section__image'>
                                                    <img src={safety} alt='Safety & security' />
                                                </div>
                                                <div className='we__section__safe__right__section__card'>
                                                    <img src={safetyicon} alt='Safety & security__icon' />
                                                    <h5>Safety & security</h5>
                                                </div>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='we__entry'>
                        <div className='we__entry__container'>
                            <div className='we__entry__banner'>
                                <div className='we__entry__banner__heading'>
                                    <Slide bottom>
                                        <h2>Keyless entry</h2>
                                    </Slide>
                                    <div className='we__entry__banner__heading__line'></div>
                                </div>
                                <div className='we__entry__banner__image'>
                                    <Slide bottom>
                                        <img src={keyless} alt='keyless' />
                                    </Slide>
                                </div>
                                <div className='we__entry__banner__content'>
                                    <Slide bottom>
                                        <p>A part of our Aware Home technology system is keyless entry equipment that once installed in the customers home, allows Yozoi app to:</p>
                                    </Slide>
                                </div>
                            </div>
                            <div className='we__entry__cards'>
                                <div className='we__entry__card'>
                                    <Slide left>
                                        <h4>1</h4>
                                        <h5>Conduct identity test in real-time on the service provider at the doorstep of the customer’s home</h5>
                                    </Slide>
                                </div>
                                <div className='we__entry__card'>
                                    <Slide bottom>
                                        <h4>2</h4>
                                        <h5>Give keyless entry to the service provide once he/she has passed the test</h5>
                                    </Slide>
                                </div>
                                <div className='we__entry__card'>
                                    <Slide right>
                                        <h4>3</h4>
                                        <h5>Let service provider enter your home if you are unable to open the door or if there is an emergency</h5>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='we__cardlist'>
                        <div className='we__cardlist__container'>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card1} alt='Service tracking' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Service tracking</h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'Yozoi app can track the presence and movements of the service providers in the customers’ homes. This helps us to assess the effectiveness and duration of the service. When combined with other data from various sensors and feedback from the customers and service providers, analytics can be performed to rate service providers’ performance with a view improving services through training or further personalisation of the services.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card2} alt='Personalised service plans' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Personalised service plans</h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'Customer or family and friends can access the app upon the customer’s permission. The app can be used to develop personalised service plans and payment schedules. Alternatively, Yozoi can provide customers a free assessment in order to develop personalised service plans. Once a service plan is initiated and sufficient data collected over the course of time, Yozoi AI can continuously improve personalisation of service plans to match any changes in the requirements of customers.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card3} alt='Customer health and wellbeing management' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Customer health and wellbeing management </h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'Yozoi provides support in real-time for independent living, health insights & early actions to improve wellbeing of senior citizens. Yozoi’s Aware Home technology system can be customised and installed in the customer’s home to provide continuous data stream on the customer’s daily pattern of activity and key vital signs measurements. Our Aware Home system is passive sensor system, it does not require the customer to remember to wear devices or to take self-measurements or provide samples.Yozoi’s Aware Home system together with data from service provider’s reports is used to deliver personalised services, health, and wellbeing assessment, giving early warning of wellbeing issues, reports and recommendations that enable early actions to enhance customer’s health and wellbeing and prevent emergencies.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card4} alt='Family access' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Family access </h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'Customer can give access to his/her account to a proxy who can be a family member or a friend. This enables the proxy to see the service being provided in real-time, read reports and monitor invoices and payments.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card5} alt='Service provider ratings' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Service provider ratings</h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'On delivery of a service, the service provider is given a performance rating by the customer or the customer’s proxy. This rating is used by the Yozoi app to give the service provider an overall rating by combining with performance assessment data collected by the app. The rating is continuously evaluated each time feedback is received from a customer or further data collected by the app.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card6} alt='Service provider training' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Service provider training</h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'We offer free training to service providers to help them improve their service delivery. The selection of service providers for training is carried out by Yozoi AI based on data analytics.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card7} alt='Reporting' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Reporting</h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'Yozoi app requires post-service reporting by service providers. The app enables service providers to complete service reports in a fast and simple manner. These reports are then permanently saved in the customer’s account. A report can be updated by the service provider, however, both the original and updated reports are saved. The original copy of the report can never be deleted and, therefore, there is indelible provenance of services provided to a customer and his/her wellbeing data records. The database of service provider reports is invaluable if a customer requires attention from medical workers or hospitalisation or entry into a residential care home. The data can be accessed readily the customer of his/her proxy by using the app.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                            <div className='we__cardlist__card'>
                                <div className='we__cardlist__card__banner'>
                                    <Slide bottom>
                                        <img src={card8} alt='Payment automation' />
                                    </Slide>
                                    <Slide bottom>
                                        <h3>Payment automation</h3>
                                    </Slide>
                                </div>
                                <div className='we__cardlist__card__content'>
                                    <div className='we__cardlist__card__content__line'></div>
                                    <div className='we__cardlist__card__content__section'>
                                        <Slide bottom>
                                            <ReadMoreReact text={'A service duration is timed by the Yozoi app and the type of service is also recorded, the customer is billed accordingly and a payment transaction is automatically enacted.'}
                                                min={80}
                                                ideal={100}
                                                max={1000}
                                                readMoreText={'Readmore'} />
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhatWeDoComponent;