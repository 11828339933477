import React from 'react';
import './CompanionshipStyles.scss';
import { Helmet } from 'react-helmet-async';
import banner from '../../assets/images/service-page.jpg';
import accompanying from '../../assets/images/companionship/accompanying.png';
import cafes from '../../assets/images/companionship/cafes.png';
import keepfit from '../../assets/images/companionship/gym.png';
import social from '../../assets/images/companionship/social activities.png';
import visittomedics from '../../assets/images/companionship/visit to medics.png';
import Slide from 'react-reveal/Slide';

const CompanionshipComponent = () => {
    return (
        <>
            <Helmet>
                <title>Yozoi | Companionship</title>
                <meta name='description' content='Yozoi | Companionship' />
                <meta name='keyWords' content='Yozoi | Companionship' />
            </Helmet>
            <div className='companion'>
                <div className='companion__container'>
                    <div className='companion__banner' style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>Services</h2>
                        </Slide>
                    </div>
                    <div className='companion__sections'>
                        <div className='companion__section'>
                            <div className='companion__section__heading'>
                                <Slide bottom cascade>
                                    <h2>Companionship</h2>
                                </Slide>
                                <div className='companion__section__line'></div>
                            </div>
                            <div className='companion__section__main'>
                                <div className='companion__section__container' id='accompanying-to-community-locations'>
                                    <div className='companion__section__container__left'>
                                        <div className='companion__section__container__left__image'>
                                            <Slide left>
                                                <img src={accompanying} alt='Accompanying to community locations' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='companion__section__container__right'>
                                        <div className='companion__section__container__right__content'>
                                            <Slide right>
                                                <h3>Accompanying to community locations</h3>
                                                <p>Do you need a person to accompany you  to community events or functions? Our service providers can give you transport and escort to any community event, function or any other gathering.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='companion__section__container' id='cafes-&-restaurants'>
                                    <div className='companion__section__container__left'>
                                        <div className='companion__section__container__left__image'>
                                            <Slide right>
                                                <img src={cafes} alt='Cafes & restaurants ' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='companion__section__container__right'>
                                        <div className='companion__section__container__right__content'>
                                            <Slide left>
                                                <h3>Cafes & restaurants </h3>
                                                <p>Would you like outings to cafes, restaurants, or pubs? Yozoi can provide you with transport and match you with an appropriate person to accompany you.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='companion__section__container' id='keep-fit'>
                                    <div className='companion__section__container__left'>
                                        <div className='companion__section__container__left__image'>
                                            <Slide left>
                                                <img src={keepfit} alt='Keep fit  ' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='companion__section__container__right'>
                                        <div className='companion__section__container__right__content'>
                                            <Slide right>
                                                <h3>Keep fit  </h3>
                                                <p>It is hugely important for senior citizens to physically keep fit, mobile and flexible. Together with a nutritional plan, we can develop and provide you with a fitness programme. Our personal trainer service providers can escort you to the local gym or swimming pool and help you train. We generally recommend three on hour training sessions per week. Within a short-time of 3-4 weeks, this should result in significant and visible improvements in your health and wellbeing.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='companion__section__container' id='social-activities'>
                                    <div className='companion__section__container__left'>
                                        <div className='companion__section__container__left__image'>
                                            <Slide right>
                                                <img src={social} alt='Social activities' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='companion__section__container__right'>
                                        <div className='companion__section__container__right__content'>
                                            <Slide left>
                                                <h3>Social activities</h3>
                                                <p>We can enable you to engage in social activities with matched service providers either at home or outside. For example, home visits for a cup of tea and chat or playing board games and outings to cinemas and theatres.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='companion__section__container' id='visits-to-medics'>
                                    <div className='companion__section__container__left'>
                                        <div className='companion__section__container__left__image'>
                                            <Slide left>
                                                <img src={visittomedics} alt='Visits to medics' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='companion__section__container__right'>
                                        <div className='companion__section__container__right__content'>
                                            <Slide right>
                                                <h3>Visits to medics</h3>
                                                <p>Do you need help to visit the doctor, dentist or pharmacy? Just use the Yozoi app to request a service provider to provide transport or escort.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanionshipComponent;