import React from 'react';
import './PrivactPolicyStyles.scss';

const PrivacyPolicyComponent = () => {
    return (
        <div className='privacy'>
            <div className='privacy__container'>
                <div className='privacy__banner'>
                    <div className='privacy__banner__heading'>
                        <h1>Privacy policy</h1>
                    </div>
                </div>
                <div className='privacy__sections'>
                    <div className='privacy__content'>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                {/* <h2>Yozoi privacy policy</h2> */}
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>Yozoi Limited (“Yozoi,” “we,” or “us”) offers Services which includes our website <span>www.Yozoi.com</span>, our mobile applications, and other services provided by us (hereinafter the “Service”). We provide the Service to our Users (as defined in the Terms of Service). By accessing and using the Service, which includes our mobile applications, you are accepting this Privacy Policy and are also agreeing to comply with our Terms of Service. Capitalized terms used in this Privacy Policy but not defined here have the meanings ascribed to them in the Terms of Service.</p>
                                <p>Before submitting any information that may identify you (“personally identifiable information”) to our Services, please carefully read this Privacy Policy which is designed to help you understand how we collect, use, and disclose both personally identifiable information and Wellbeing Information (as defined in the Terms of Service) (hereinafter “Information”) that we obtain when you use of our Service.</p>
                                <p>When you use our Service, Information is automatically collected using cookies and similar technologies as well as our Home Aware system and 2-way feedback provided by customers and service providers.  We will ask your consent when required during registration and as part of the process to personalise Services.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>What you need to understand before using services</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <h3>Before using and sharing Information through our service, you should understand the following.</h3>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>1.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>You should read and agree with our terms of service.</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>2.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>The information that we collect is transferred to Yozoi Limited in the United Kingdom.</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>3.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>If you have questions, you can always contact us at <span>users@Yozoi.com</span> </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>The personal data we collect</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>We receive and store Information you provide to us by any means.  We also collect certain Information automatically when you use our Service. </p>
                                <p>Information we collect includes Information collected automatically using cookies and similar technologies.  Cookies collect different types of information, including personally identifiable information. This kind of Information may include IP addresses, session information, product usage data, tracking data and other information about your use of our Service.</p>
                                <p>When you are a registered User of our Service, we collect more Information to allow you to benefit fully from the use Service personalised for you.  We will need your email address, information about your gender and general age, and will request your name, address, telephone, your use of assistive devices, handedness, Well-being Information, and an image of you to undertake identity validation. You will also be asked several questions during the registration process to ensure safety and security of our Services.</p>
                                <p>We also obtain Information about you via the 2-way customers and service providers feedback process.  We may also receive data from third parties or sensors installed in your home to personalise Services in accord with your wishes and agreement.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>The uses of your information</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>Yozoi uses the Information collected to register you, to manage your account, to communicate with you, and provide you personalised Services.  We also use your Information to permit those providing Services to you to have the information necessary to effectively provide you with Services. Upon your request, we can also make your Wellbeing information available to third parties such as care homes, hospitals, medical practitioners or family and friends you have appointed as your proxy.</p>
                                <p>We may use your Information as defined in the Terms of Service and to provide and/or improve the Service.  We may use your Information in an anonymized manner to develop additional products and services or to measure and improve our products and services and to develop or improve third party products and services.</p>
                                <p>We use your Information when you authorise us to use it and when it is necessary to enforce this Privacy Policy and our Terms of Service including but not limited to below items:</p>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>1.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>for registration and to manage your account, including to facilitate your use of our Service;</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>2.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>to communicate with you and respond to your questions and feedback;</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>3.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>to resolve disputes or troubleshoot problems;</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>4.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>to prevent prohibited or illegal activities;</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>5.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>to enforce our Terms of Service and Privacy Policy;</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>6.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>to share your information with service providers when you request Services;</h4>
                                    </div>
                                </div>
                                <div className='privacy__content__section__content__points'>
                                    <div className='privacy__content__section__content__points__num'>
                                        <h4>7.</h4>
                                    </div>
                                    <div className='privacy__content__section__content__points__data'>
                                        <h4>to comply with a legal obligation such as court orders, or other legal process; to establish or exercise our legal rights; to defend against legal claims; or as otherwise required by law.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>The sharing of your information</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>Your Information is only shared with service providers and to the extent necessary for provision of the Services.  The service provider can only see components of the Information you have submitted to our Service to help the service provider to provide the Service for which you have engaged that service provider.</p>
                                <p>We may share aggregated data and high-level information in a way that does not identify individuals in order to improve our Services.</p>
                                <p>On occasions, we share your Information for processing and storage with third parties that have signed confidential disclosure agreements with us to protect the confidentiality and security of information that they access.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>Your privacy rights</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>We abide by European Union (the “EU”) regulations and allow for the exercise of your rights provided under data protection laws of the EU, including the General Data Protection Regulation (the “GDPR”).</p>
                                <p>You are therefore, entitled to:</p>
                                <p>Transparency and the right to information. Through this policy, we explain how we use and share your information. If you have questions or concerns you may contact us any time.</p>
                                <p>Right of access, objection, restriction of processing, erasure, and portability. You also have the right to withdraw your consent at any time when we process your personal data based on your consent. To exercise these rights, please email <span>users@Yozoi.com</span>.</p>
                                <p>Right to opt-out of direct marketing.  Currently, do not do such marketing. If and when we do such marketing, you have the right to opt-out at any time to receiving marketing materials from us by following the opt-out instructions in our commercial emails, by contacting us, or by adjusting your preferences on the Service.  Please note that we reserve the right to send you other communications, including service announcements and administrative messages relating to your Yozoi account and your Wellbeing, without offering you the opportunity to opt out of receiving them.</p>
                                <p>Right not to be subject to an automated decision, including profiling. Yozoi does not make automated decisions using your personal data that may negatively impact you. We use cookies and similar technologies in our Service only to personalise your experience of our Service.</p>
                                <p>Right to lodge a complaint with a regulatory authority. If you consider that the processing of your personal data infringes your privacy rights according to the European Data Protection law, you have the right to lodge a complaint with a regulatory authority, in the member state of your residence, place of work, or place of the alleged infringement.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>Functionality of do not track technologies </h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>Some browsers may have settings to request that websites “Do Not Track” your activity or usage.  At this time, we do not use services that will accommodate these technologies and we will not comply with your requests on our Service.  We do use cookies and other technologies to track your usage and other information about your visit to our Service in order to personalise services and ensure your wellbeing.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>Information retention periods and deletion</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>We retain your Information to the extent necessary to provide the services and meet the Service requirements, to meet our compliance and legal obligations, to enhance security and fraud prevention, and for audit purposes.  We will keep your Information in accordance with our Terms of Service.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>The protection of your Information</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>We are committed to protecting the personally identifiable information we collect. While no website can guarantee absolute security, we have implemented best possible administrative, technical, and physical security procedures to help protect the personally identifiable information you provide to us.  For example, only authorised employees are permitted to access personally identifiable information of users strictly for permitted business functions. Our security methods and privacy policy are periodically reviewed and enhanced using best available technology, and only authorised personnel have access to personally identifiable information. Additionally, we use encryption when transmitting personally identifiable information and employ firewalls and intrusion detection systems to help prevent hacking. However, you should bear in mind that the submission of Information over the Internet is never secure absolutely. We cannot guarantee the security of Information you submit via our Service while it is in transit over the Internet, and any such submission is at your own risk.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>Changes to this policy</h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>Yozoi may update this Privacy Policy in the future. We will notify you about material changes to this Privacy Policy by either sending a notice to the email address you provided to us during registration and/or by placing a prominent notice on the Service.</p>
                            </div>
                        </div>
                        <div className='privacy__content__section'>
                            <div className='privacy__content__section__heading'>
                                <h2>Contacting us </h2>
                            </div>
                            <div className='privacy__content__section__content'>
                                <p>If you have questions or wish to communicate about this Privacy Policy, please contact us at <span>users@Yozoi.com</span> via postal mail at Yozoi Limited, 124 City Road, London, EC4V 2NX, United Kingdom. When contacting us, please provide your Yozoi identification number and email address used to register with our Service.</p>
                                <p>Last Updated on 15th March 2023.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicyComponent;