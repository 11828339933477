import React from 'react';
import about from '../../assets/images/about.jpg';
import './AboutYozoi.scss';
import { Helmet } from 'react-helmet-async';
import Slide from 'react-reveal/Slide';
import { useNavigate } from 'react-router-dom';

const AboutYozoi = () => {
    const navigate = useNavigate();

    const handlehome = () => {
        navigate('/home-visits');
        window.scrollTo(0, 0);
    }

    const handleCompanion = () => {
        navigate('/companionship');
        window.scrollTo(0, 0);
    }

    const handleAware = () => {
        navigate('/aware-home-system');
        window.scrollTo(0, 0);
    }
    
    return (
        <>
            <Helmet>
                <title>Yozoi | Learn About Yozoi Services</title>
                <meta name="description" content="Yozoi | Learn About Yozoi Services" />
                <meta name="keyWords" content="Yozoi | Learn About Yozoi Services" />
            </Helmet>
            <div className='learn'>
                <div className='learn__container'>
                    <div className='learn__banner' style={{
                        backgroundImage: `url(${about})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>Learn about Yozoi services </h2>
                        </Slide>
                    </div>
                    <div className='learn__section'>
                        <div className='learn__section__content'>
                            <Slide bottom>
                                <h4>
                                    Yozoi means hello life, Yo is hello and Zoi means life. We offer on-demand 24/7 domiciliary support services to busy professionals, retirees, and senior citizens to ease the pressures of daily life. We differentiate ourselves through our service principles of flexibility, quality, care, compassion and the latest in digital technologies. Our service providers deliver a bespoke and professional service with the utmost kindness and concern for the well-being of our customers. The services are booked through the Yozoi app, the technology embedded in our app, monitors the service to ensure utmost quality. The services include those shown below, please contact us if the service you need is not in the list, we are always happy to offer customised services.
                                </h4>
                            </Slide>
                            <Slide bottom>
                                <h4>
                                    Yozoi services are offered throughout the week 24/7. However, we encourage customers to be socially responsible and book in advance if their service need is not urgent. This helps us to first service those customers who really need an urgent service. Different rates apply at peak and off-peak times. Peak time is from 18.00 to 10.00 hours and off-peak time is from 10.00 to 18.00 hours. Check out our full range of services by clicking the links below.
                                </h4>
                            </Slide>
                        </div>
                        <div className='learn__section__links'>
                            <div className='learn__section__link'>
                                <h4>Home support visits </h4>
                                <span>&#187;</span>
                                <h6 onClick={handlehome}>read more</h6>
                            </div>
                            <div className='learn__section__link'>
                                <h4>Companionship </h4>
                                <span>&#187;</span>
                                <h6 onClick={handleCompanion}>read more</h6>
                            </div>
                            <div className='learn__section__link'>
                                <h4>Aware home system </h4>
                                <span>&#187;</span>
                                <h6 onClick={handleAware}>read more</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutYozoi;