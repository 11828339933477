import React, { useState, useEffect } from "react";
import "./FillDetails.scss";
import { useNavigate } from "react-router-dom";
import { RegisterServiceProvider } from "../../components/utils/apicalls";
import Confirm from "../../components/confirmModal/confirm";
import Loader from "../../components/loader";
import { useParams } from "react-router";
import CryptoJS from "crypto-js";
import {
  SelectServicesList,
  GetCountryList,
} from "../../components/utils/apicalls";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Flag from "react-flagkit";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FillDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState();
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [cityName, setCityName] = useState();
  const [stateName, setStateName] = useState();
  const [zipCode, setZipCode] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  // const getEmail = sessionStorage.getItem('getEmail');
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [alertText, setAlertText] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModal1, setShowConfirmModal1] = useState(false);
  const getEmail = sessionStorage.getItem("getEmail");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [errorMessage3, setErrorMessage3] = useState("");
  const [errorMessage4, setErrorMessage4] = useState("");

  const [servicesList, setServicesList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormValid1, setIsFormValid1] = useState(false);
  const [isFormValid2, setIsFormValid2] = useState(false);
  const [isFormValid3, setIsFormValid3] = useState(false);
  const [isFormValid4, setIsFormValid4] = useState(false);

  const password = CryptoJS.enc.Utf8.parse("7819461082573529");

  const [checked, setChecked] = useState([]);

  const [topThree, setTopThree] = useState([]);
  const [topNotThree, setNotTopThree] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  useEffect(() => {
    getServicesList();
    getCountryList();
  }, []);

  const getServicesList = () => {
    SelectServicesList((res) => {
      const { message, statusCode, data } = res;
      setServicesList(data);
    });
  };

  const getCountryList = () => {
    GetCountryList((res) => {
      const { message, statusCode, data } = res;
      setCountryList(data);
      const filteredData = data.slice(3);
      console.log(filteredData);
      setNotTopThree(filteredData);
    });
  };

  const onFileChange1 = (event) => {
    const file = event.target.files[0];
    // setFile1(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile1(reader.result?.split(",")[1]);
    };
    reader.onerror = function () {
      console.log(reader.error);
    };
  };

  const onFileChange2 = (event) => {
    const file = event.target.files[0];
    // setFile1(file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile2(reader.result?.split(",")[1]);
    };
    reader.onerror = function () {
      console.log(reader.error);
    };
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmailId(value);

    // validate email format
    if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      setIsFormValid4(false);
      setErrorMessage4("Invalid email format");
    } else {
      setIsFormValid4(true);
      setErrorMessage4("");
    }
  };

  const handleMobileChange = (event) => {
    const value = event.target.value;
    setPhone(value);

    // validate email format
    if (!value.match(/^([0|\+[0-9]{1,9})?([0-9][0-9]{6})$/)) {
      setIsFormValid(false);
      setErrorMessage("Invalid mobile number format");
    } else {
      setIsFormValid(true);
      setErrorMessage("");
    }
  };

  const handleZipChange = (event) => {
    const value = event.target.value;
    setZipCode(value);

    // validate email format
    // if (!value.match(/^[a-zA-Z0-9]{0,1000}$/)) {
    //   setIsFormValid1(false);
    //   setErrorMessage1("Invalid zip/postal code format");
    // } else
    if (value.length <= 0) {
      setIsFormValid1(false);
      setErrorMessage1("Minimum 1 letters required");
    } else {
      setIsFormValid1(true);
      setErrorMessage1("");
    }
  };

  const handleFirstChange = (event) => {
    const value = event.target.value;
    setFirstName(value);

    // validate email format
    if (!value.match(/^[a-zA-Z]{0,2000}$/)) {
      setIsFormValid2(false);
      setErrorMessage2("Special charecters and number are not allowed");
    } else if (value.length <= 0) {
      setIsFormValid2(false);
      setErrorMessage2("Minimum 1 letters required");
    } else {
      setIsFormValid2(true);
      setErrorMessage2("");
    }
  };

  const handlelastChange = (event) => {
    const value = event.target.value;
    setLastName(value);

    // validate email format
    if (!value.match(/^[a-zA-Z]{0,2000}$/)) {
      setIsFormValid3(false);
      setErrorMessage3("Special charecters and number are not allowed");
    } else if (value.length <= 0) {
      setIsFormValid3(false);
      setErrorMessage3("Minimum 1 letters required");
    } else {
      setIsFormValid3(true);
      setErrorMessage3("");
    }
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const formData = {
  //     phoneNumber: "7892954719",
  //     isAgreedToTerms: true,
  //     role: 2,
  //     code: "55555",
  //     countryCode: "+91",
  //     countryName: "India"
  //   };

  //   fetch('http://43.205.196.43/api-docs/api/Account/Login', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(formData)
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       // handle the response data here
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       // handle the error here
  //     });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      firstName: CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(firstName),
        password,
        {
          keySize: 128 / 8,
          iv: password,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(),
      lastName: CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(lastName),
        password,
        {
          keySize: 128 / 8,
          iv: password,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(),
      email: CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(emailId), password, {
        keySize: 128 / 8,
        iv: password,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(),
      phoneNumber: CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(phone),
        password,
        {
          keySize: 128 / 8,
          iv: password,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(),
      countryCode: countryCode
        ? CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(`+${countryCode}`),
            password,
            {
              keySize: 128 / 8,
              iv: password,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7,
            }
          ).toString()
        : CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse("+44"), password, {
            keySize: 128 / 8,
            iv: password,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }).toString(),
      firstDocument: file1,
      secondDocument: file2,
      selectedServices: checked,
      addressLineOne: CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(address1),
        password,
        {
          keySize: 128 / 8,
          iv: password,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(),
      addressLineTwo: CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(address2),
        password,
        {
          keySize: 128 / 8,
          iv: password,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(),
      city: CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(cityName), password, {
        keySize: 128 / 8,
        iv: password,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(),
      stateOrRegion: CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(stateName),
        password,
        {
          keySize: 128 / 8,
          iv: password,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(),
      postalCode: CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(zipCode),
        password,
        {
          keySize: 128 / 8,
          iv: password,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      ).toString(),
      countryID: selectedCountry,
      isAcceptedTermsAndCondtions: checked1,
      doyouhaveavalidUKDBScertificate: checked2,
    };
    setIsShowLoader(true);
    console.log(isFormValid);
    if (payload) {
      if (
        firstName &&
        lastName &&
        emailId &&
        file1 &&
        file2 &&
        address1 &&
        address2 &&
        cityName &&
        stateName &&
        zipCode &&
        selectedCountry
      ) {
        if (checked1 === true) {
          if (
            isFormValid === true &&
            isFormValid1 === true &&
            isFormValid2 === true &&
            isFormValid4 === true &&
            isFormValid3 === true
          ) {
            setIsShowLoader(true);
            RegisterServiceProvider((res) => {
              const { message, statusCode } = res;
              if (statusCode === 200) {
                setIsShowLoader(false);
                setShowConfirmModal(true);
                setShowConfirmModal1(false);
                setAlertText(
                  "Thank you for registering, admin will  notify you once your account is approved"
                );
                // window.location.reload();
              } else {
                setShowConfirmModal(false);
                setShowConfirmModal1(true);
                setIsShowLoader(false);
                setAlertText(message);
              }
            }, payload);
          } else {
            setShowConfirmModal1(true);
            setShowConfirmModal(false);
            setIsShowLoader(false);
            setAlertText("Please enter valid data");
          }
        } else {
          setShowConfirmModal1(true);
          setShowConfirmModal(false);
          setIsShowLoader(false);
          setAlertText("Please agree the terms of service and privacy policy");
        }
      } else {
        setShowConfirmModal1(true);
        setShowConfirmModal(false);
        setIsShowLoader(false);
        setAlertText("fill all the details");
      }
    }
  };

  const handleHome = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const getFlag = () => {
    return <Flag />;
    return null;
  };

  return (
    <div className="fill">
      <div className="fill__container">
        <div className="fill__top">
          <div className="fill__top__header">
            <h2>Please fill-in your details</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="fill__top__inputs">
              <div className="fill__top__input">
                <label>First name</label>
                <input
                  type="text"
                  placeholder="First name"
                  value={firstName}
                  onChange={(e) => handleFirstChange(e)}
                />
                {errorMessage2 && (
                  <p style={{ color: "red" }}>{errorMessage2}</p>
                )}
              </div>
              <div className="fill__top__input">
                <label>Last name</label>
                <input
                  type="text"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(e) => handlelastChange(e)}
                />

                {errorMessage3 && (
                  <p style={{ color: "red" }}>{errorMessage3}</p>
                )}
              </div>
              <div className="fill__top__input">
                <label>Email Address *</label>
                <input
                  placeholder="Email Address *"
                  type="email"
                  onChange={(e) => handleEmailChange(e)}
                />
                {errorMessage4 && (
                  <p style={{ color: "red" }}>{errorMessage4}</p>
                )}
              </div>
              <div className="fill__top__input num">
                <label>Phone Number</label>
                <div className="fill__top__input__inputs">
                  <PhoneInput
                    country={"gb"}
                    placeholder="Country Code"
                    value={countryCode}
                    onChange={setCountryCode}
                    maxLength={4}
                    countryComponent={getFlag()}
                    readOnly={true}
                  />
                  <input
                    className="fill-num"
                    placeholder="Phone Number *"
                    maxLength={15}
                    onChange={(e) => handleMobileChange(e)}
                  />
                </div>
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
              </div>
              <div className="fill__top__input">
                <label>Upload proof of address</label>
                <input type="file" id="fileInput" onChange={onFileChange1} />
                <p>
                  (utility bill issues within the past 3 months or driving
                  license)
                </p>
              </div>
              <div className="fill__top__input">
                <label>Upload proof of id</label>
                <input type="file" id="fileInput" onChange={onFileChange2} />
                <p>
                  (passport picture page, national id card or picture driving
                  license)
                </p>
              </div>
              <div className="fill__top__input">
                <label>Address line 1</label>
                <input
                  type="text"
                  placeholder="Address "
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
                <p>House number or name</p>
                {/* <p>Street address, p.o. box, company name, c/o</p> */}
              </div>
              <div className="fill__top__input">
                <label>Address line 2</label>
                <input
                  type="text"
                  placeholder="Address line 2"
                  value={address2}
                  onChange={(e) => setAddress2(e.target.value)}
                />
                <p>Road or street name</p>
                {/* <p>Apartment, suite, unit, building, floor, etc.</p> */}
              </div>
              <div className="fill__top__input">
                <label>City</label>
                <input
                  type="text"
                  placeholder="City"
                  value={cityName}
                  onChange={(e) => setCityName(e.target.value)}
                />
              </div>
              <div className="fill__top__input">
                <label>County/Province/State</label>
                <input
                  type="text"
                  placeholder="County/Province/State"
                  value={stateName}
                  onChange={(e) => setStateName(e.target.value)}
                />
              </div>
              <div className="fill__top__input">
                <label>Zip/Postal code</label>
                <input
                  type="text"
                  placeholder="Zip/Postal code"
                  value={zipCode}
                  maxLength={15}
                  onChange={(e) => handleZipChange(e)}
                />
                {errorMessage1 && (
                  <p style={{ color: "red" }}>{errorMessage1}</p>
                )}
              </div>
              <div className="fill__top__input">
                <label>Country</label>
                <select
                  type="text"
                  placeholder="Select country"
                  onChange={(event) => setSelectedCountry(event.target.value)}
                  className="addproduct__section__div__customInput"
                  defaultValue={selectedCountry}
                >
                  <option key={0} value={0}>
                    {" "}
                    -- Select country --{" "}
                  </option>
                  <option value={countryList[0]?.id}>{countryList[0]?.name}</option>
                  <option value={countryList[1]?.id}>{countryList[1]?.name}</option>
                  <option value={countryList[2]?.id}>{countryList[2]?.name}</option>
                  <option key={0} value={0}>
                    {" "}
                    -----------------------------------------------------------------{" "}
                  </option>
                  {topNotThree.length &&
                    topNotThree.map((val, index) => {
                      return (
                        <option key={index} value={val?.id}>
                          {val?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {/* <div className="fill__top__checkboxes">
              <div className="fill__top__checkboxes__header">
                <h2>
                  Please click the check box (Tick sign image put) to confirm
                  your services
                </h2>
              </div>
              <div className="fill__top__checkboxes__heading">
                <h3>Home support visits</h3>
              </div>
              <div className="fill__top__checkboxes__list">
                <div className="fill__top__checkboxes__list__check">
                  <label>Bedding</label>
                  <input
                    type="checkbox"
                    defaultValue={bedding}
                    onChange={() => setBedding(!bedding)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Cleaning</label>
                  <input
                    type="checkbox"
                    defaultValue={cleaning}
                    onChange={() => setCleaning(!cleaning)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Footcare</label>
                  <input
                    type="checkbox"
                    defaultValue={footcare}
                    onChange={() => setFootcare(!footcare)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Gardening</label>
                  <input
                    type="checkbox"
                    defaultValue={gardening}
                    onChange={() => setGardening(!gardening)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Haircare</label>
                  <input
                    type="checkbox"
                    defaultValue={haircare}
                    onChange={() => setHaircare(!haircare)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Handyperson</label>
                  <input
                    type="checkbox"
                    defaultValue={handyperson}
                    onChange={() => setHandyperson(!handyperson)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Laundry</label>
                  <input
                    type="checkbox"
                    defaultValue={laundry}
                    onChange={() => setLaundry(!laundry)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Mobility assistance</label>
                  <input
                    type="checkbox"
                    defaultValue={mobility}
                    onChange={() => setMobility(!mobility)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Pets</label>
                  <input
                    type="checkbox"
                    defaultValue={pets}
                    onChange={() => setPets(!pets)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Preparing meals</label>
                  <input
                    type="checkbox"
                    defaultValue={meals}
                    onChange={() => setMeals(!meals)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Shopping assistance</label>
                  <input
                    type="checkbox"
                    defaultValue={shopping}
                    onChange={() => setShopping(!shopping)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Security assistance</label>
                  <input
                    type="checkbox"
                    defaultValue={security}
                    onChange={() => setSecurity(!security)}
                  />
                </div>
              </div>
              <div className="fill__top__checkboxes__heading">
                <h3>Companionship</h3>
              </div>
              <div className="fill__top__checkboxes__list">
                <div className="fill__top__checkboxes__list__check">
                  <label>Accompanying to community locations</label>
                  <input
                    type="checkbox"
                    defaultValue={community}
                    onChange={() => setCommunity(!community)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Cafes & restaurants</label>
                  <input
                    type="checkbox"
                    defaultValue={cafes}
                    onChange={() => setCafes(!cafes)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Keep fit</label>
                  <input
                    type="checkbox"
                    defaultValue={keepfit}
                    onChange={() => setKeepfit(!keepfit)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Social activities</label>
                  <input
                    type="checkbox"
                    defaultValue={social}
                    onChange={() => setSocial(!social)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Visits to medics</label>
                  <input
                    type="checkbox"
                    defaultValue={medics}
                    onChange={() => setMedics(!medics)}
                  />
                </div>
              </div>
              <div className="fill__top__checkboxes__heading">
                <h3>Aware home system</h3>
              </div>
              <div className="fill__top__checkboxes__list">
                <div className="fill__top__checkboxes__list__check">
                  <label>Accident detection</label>
                  <input
                    type="checkbox"
                    defaultValue={accident}
                    onChange={() => setAccident(!accident)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Intrusion detection</label>
                  <input
                    type="checkbox"
                    defaultValue={intrusion}
                    onChange={() => setIntrusion(!intrusion)}
                  />
                </div>
                <div className="fill__top__checkboxes__list__check">
                  <label>Wellbeing security</label>
                  <input
                    type="checkbox"
                    defaultValue={wellbeing}
                    onChange={() => setWellbeing(!wellbeing)}
                  />
                </div>
              </div>
            </div> */}
            <div className="fill__top__checkboxes">
              <div className="fill__top__checkboxes__header">
                <h2>
                  Please read through below list of services and tick the ones
                  you can provide
                </h2>
              </div>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {servicesList.map((parentService) => (
                  <div key={parentService?.id}>
                    <h3>{parentService?.name}</h3>
                    <List component="div" disablePadding>
                      <div className="fill__top__checkboxes__list">
                        {parentService?.ServiceCategories?.map((category) => (
                          <div
                            className="fill__top__checkboxes__list__check"
                            key={category?.id}
                          >
                            <ListItem sx={{ pl: 4 }}>
                              <ListItemButton
                                onClick={handleToggle(category?.id)}
                              >
                                <Checkbox
                                  checked={checked.indexOf(category?.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": `checkbox-list-label-${category?.id}`,
                                  }}
                                />
                                <ListItemText
                                  id={`checkbox-list-label-${category?.id}`}
                                  primary={category?.name}
                                />
                              </ListItemButton>
                            </ListItem>
                          </div>
                        ))}
                      </div>
                    </List>
                  </div>
                ))}
              </List>
            </div>
            <div className="fill__top__dbs">
            <Checkbox
                type="checkbox"
                onChange={() => setChecked2(!checked2)}
              />
              <p>
              Do you have a valid UK DBS certificate?
              </p>
            </div>
            <div className="fill__top__terms">
              <Checkbox
                type="checkbox"
                // defaultValue={checked1}
                onChange={() => setChecked1(!checked1)}
              />
              <p>
                I have read and agreed to the{" "}
                <a href="https://yozoi.com/terms" target="_blank">
                  Terms of service
                </a>{" "}
                and{" "}
                <a href="https://yozoi.com/privacy-policy" target="_blank">
                  Privacy policy
                </a>
                .
              </p>
            </div>
            <div className="fill__top__button">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      {showConfirmModal && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={alertText}
          onConfirm={handleHome}
          onCancel={() => {
            setShowConfirmModal(false);
          }}
        />
      )}
      {showConfirmModal1 && (
        <Confirm
          buttonText={"OK"}
          isCancelRequired={false}
          confirmTitle={alertText}
          onConfirm={() => {
            setShowConfirmModal1(false);
          }}
          onCancel={() => {
            setShowConfirmModal1(false);
          }}
        />
      )}
      {isShowLoader ? <Loader /> : null}
    </div>
  );
};

export default FillDetails;
