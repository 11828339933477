import React, { useState } from "react";
import logo from "../../assets/images/Yozoi.png";
import "./header.scss";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import MenuItems from "../components/MenuItems";
import MenuItems1 from "../components/MenuItems1";
import { menuItems, menuItems1 } from "../components/items/menuItems";
import { NavLink } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isOpen1, setOpen1] = useState(false);
  const [isOpenSub, setOpenSub] = useState(false);
  const [isOpenSub1, setOpenSub1] = useState(false);
  const [isOpenSub2, setOpenSub2] = useState(false);
  const [isOpenSub3, setOpenSub3] = useState(false);

  const closeMenu = () => {
    setIsNavExpanded(false);
  };

  const handleClickScroll = () => {
    window.scrollTo(0, 0);
  };

  const toggleDropdown = () => setOpen(!isOpen);
  const toggleDropdown1 = () => setOpen1(!isOpen1);
  const subDropdown = () => setOpenSub(!isOpenSub);
  const subDropdown1 = () => setOpenSub1(!isOpenSub1);
  const subDropdown2 = () => setOpenSub2(!isOpenSub2);
  const subDropdown3 = () => setOpenSub3(!isOpenSub3);

  return (
    <div>
      <div className="header fixed-header fixed-top" id="navbar">
        <nav className="navigation">
          <a className="header-name" onClick={closeMenu}>
            <Link to="/" onClick={handleClickScroll}>
              <img src={logo} alt="" />
            </Link>
          </a>
          <div
            className={isNavExpanded ? "header-menu expanded" : "header-menu"}
          >
            <div className="header__menus">
              <div className="header__left-menu">
                <nav className="mobile-header-left">
                  <ul className="menus">
                    {menuItems.map((menu, index) => {
                      const depthLevel = 0;
                      return (
                        <MenuItems1
                          items={menu}
                          key={index}
                          depthLevel={depthLevel}
                        />
                      );
                    })}
                    <div className="mobile">
                      {menuItems1.map((menu, index) => {
                        const depthLevel = 0;
                        return (
                          <MenuItems
                            items={menu}
                            key={index}
                            depthLevel={depthLevel}
                          />
                        );
                      })}
                      <button className="login">Login</button>
                      <button className="sign-up">
                        <NavLink to="registration">Sign up</NavLink>
                      </button>
                    </div>
                  </ul>
                </nav>
                <ul className="mobile-header">
                  <li>
                    <div className="dropdown2">
                      <div
                        className="dropdown2-header"
                        onClick={toggleDropdown1}
                      >
                        Company
                        <i className="fa fa-chevron-down icon" />
                      </div>
                      <div className={`dropdown2-body ${isOpen1 && "open1"}`}>
                        <div
                          className="dropdown2-item"
                          onClick={() => {
                            setIsNavExpanded(!isNavExpanded);
                          }}
                        >
                          <NavLink to="/about-us">About us</NavLink>
                        </div>
                        <div
                          className="dropdown2-item"
                          onClick={() => {
                            setIsNavExpanded(!isNavExpanded);
                          }}
                        >
                          <NavLink to="/our-technology">Our technology</NavLink>
                        </div>
                        <div
                          className="dropdown2-item"
                          onClick={() => {
                            setIsNavExpanded(!isNavExpanded);
                          }}
                        >
                          <NavLink to="/how-we-work">How we work</NavLink>
                        </div>
                        <div
                          className="dropdown2-item"
                          onClick={() => {
                            setIsNavExpanded(!isNavExpanded);
                          }}
                        >
                          <NavLink to="/why-work-at-yozoi">
                            Why work at Yozoi
                          </NavLink>
                        </div>
                        {/* <div className="dropdown2-item" onClick={() => {
                                                    setIsNavExpanded(!isNavExpanded);
                                                }}>
                                                    <NavLink to='/team'>
                                                        team
                                                    </NavLink>
                                                </div> */}
                      </div>
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setIsNavExpanded(!isNavExpanded);
                    }}
                  >
                    <NavLink to="/safety">Safety</NavLink>
                  </li>
                  {/* <li>
                                        <HashLink smooth to='' style={{ cursor: 'pointer' }}>
                                            FAQ
                                        </HashLink>
                                    </li> */}
                  <li className="mobile">
                    <div className="dropdown1">
                      <div
                        className="dropdown1-header"
                        onClick={toggleDropdown}
                      >
                        Services
                        <i className="fa fa-chevron-down icon" />
                      </div>
                      <div className={`dropdown1-body ${isOpen && "open"}`}>
                        <div className="dropdown1-item" onClick={subDropdown}>
                          Home Visits
                          <i className="fa fa-chevron-down icon1" />
                        </div>
                        <div
                          className={`dropdownsub-body ${
                            isOpenSub && "opensub"
                          }`}
                        >
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#bedding"
                              style={{ cursor: "pointer" }}
                            >
                              Bedding
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#cleaning"
                              style={{ cursor: "pointer" }}
                            >
                              Cleaning
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#footcare"
                              style={{ cursor: "pointer" }}
                            >
                              Footcare
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#gardening"
                              style={{ cursor: "pointer" }}
                            >
                              Gardening
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#hair-care"
                              style={{ cursor: "pointer" }}
                            >
                              Hair care
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#handyperson"
                              style={{ cursor: "pointer" }}
                            >
                              Handyperson
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#laundry"
                              style={{ cursor: "pointer" }}
                            >
                              Laundry
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#mobility-assistance"
                              style={{ cursor: "pointer" }}
                            >
                              Mobility assistance
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#pets"
                              style={{ cursor: "pointer" }}
                            >
                              Pets
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#preparing-meals"
                              style={{ cursor: "pointer" }}
                            >
                              Preparing meals
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#shopping-assistance"
                              style={{ cursor: "pointer" }}
                            >
                              Shopping assistance
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/home-visits#security-assistance"
                              style={{ cursor: "pointer" }}
                            >
                              Security assistance
                            </HashLink>
                          </div>
                        </div>
                        <div className="dropdown1-item" onClick={subDropdown1}>
                          Companionship
                          <i className="fa fa-chevron-down icon1" />
                        </div>
                        <div
                          className={`dropdownsub1-body ${
                            isOpenSub1 && "opensub1"
                          }`}
                        >
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/companionship#accompanying-to-community-locations"
                              style={{ cursor: "pointer" }}
                            >
                              Accompanying to community locations
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/companionship#cafes-&-restaurants"
                              style={{ cursor: "pointer" }}
                            >
                              Cafes & restaurants
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/companionship#keep-fit"
                              style={{ cursor: "pointer" }}
                            >
                              Keep fit
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/companionship#cocial-activities"
                              style={{ cursor: "pointer" }}
                            >
                              Social activities
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/companionship#visits-to-medics"
                              style={{ cursor: "pointer" }}
                            >
                              Visits to medics
                            </HashLink>
                          </div>
                        </div>
                        <div className="dropdown2-item" onClick={subDropdown2}>
                          Aware home system
                          <i className="fa fa-chevron-down icon1" />
                        </div>
                        <div
                          className={`dropdownsub2-body ${
                            isOpenSub2 && "opensub2"
                          }`}
                        >
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/aware-home-system#accident-detection"
                              style={{ cursor: "pointer" }}
                            >
                              Accident detection
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/aware-home-system#intrusion-detection"
                              style={{ cursor: "pointer" }}
                            >
                              Intrusion detection
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/aware-home-system#wellbeing-security"
                              style={{ cursor: "pointer" }}
                            >
                              Wellbeing security
                            </HashLink>
                          </div>
                        </div>
                        <div
                          className="dropdown3-item"
                          onClick={subDropdown3}
                        >
                          Child Care
                          <i className="fa fa-chevron-down icon1" />
                        </div>
                        <div
                          className={`dropdownsub3-body ${
                            isOpenSub3 && "opensub3"
                          }`}
                        >
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/ChildCare#Philosophy"
                              style={{ cursor: "pointer" }}
                            >
                              Philosophy
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/ChildCare#School-Pick-ups-and-care"
                              style={{ cursor: "pointer" }}
                            >
                              School pick-ups and care
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/ChildCare#Home-care-visits"
                              style={{ cursor: "pointer" }}
                            >
                              Home care visits
                            </HashLink>
                          </div>
                          <div
                            className="dropdownsub1-item"
                            onClick={() => {
                              setIsNavExpanded(!isNavExpanded);
                            }}
                          >
                            <HashLink
                              smooth
                              to="/ChildCare#Activities"
                              style={{ cursor: "pointer" }}
                            >
                              Activities
                            </HashLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <Tooltip title="currently not avaialble" placement="bottom">
                    <li className="mobile">
                      <button className="login">Login</button>
                    </li>
                  </Tooltip>
                  <li className="mobile">
                    <button className="sign-up">
                      <NavLink to="registration">Sign up</NavLink>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="header__right-menu">
                <nav>
                  <ul className="menus">
                    {menuItems1.map((menu, index) => {
                      const depthLevel = 0;
                      return (
                        <MenuItems
                          items={menu}
                          key={index}
                          depthLevel={depthLevel}
                        />
                      );
                    })}

                    <Tooltip title="currently not avaialble" placement="bottom">
                      <button className="login">Login</button>
                    </Tooltip>
                    <button className="sign-up">
                      <NavLink to="registration">Sign up</NavLink>
                    </button>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setIsNavExpanded(!isNavExpanded);
            }}
            className="hamburger"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="white"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default Header;
