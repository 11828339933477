import React from 'react';
import './registration.scss';
import { NavLink } from 'react-router-dom';
import Slide from 'react-reveal/Slide';

const Registration = () => {
    return (
        <div className='registration'>
            <div className='registration__container'>
                <div className='registration__sections'>
                    <div className='registration__left'>
                        <NavLink to='/fill'>
                            <Slide bottom>
                                <h2>Sign up to become a service provider</h2>
                            </Slide>
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                    <div className='registration__right'>
                        <NavLink to='/registration/service'>
                            <Slide bottom>
                                <h2>Book domiciliary services</h2>
                            </Slide>
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration;