import React from 'react';
import './TermsAndConditionsStyles.scss';

const TermsAndConditionsComponent = () => {
    return (
        <div className='terms'>
            <div className='terms__container'>
                <div className='terms__banner'>
                    <div className='terms__banner__heading'>
                        <h1>Terms of service</h1>
                    </div>
                </div>
                <div className='terms__sections'>
                    <div className='terms__content'>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                {/* <h2>Terms of service</h2> */}
                            </div>
                            <div className='terms__content__section__content'>
                              <p>Yozoi limited (“Yozoi,” “we,” or “us”) service include our website <span>www.Yozoi.com</span>, mobile applications, and any other services provided by us (hereinafter, the “service”). These terms of service are a legally binding contract between you and Yozoi regarding your use of the service.</p>
                              <p><b>Our service is not intended for emergency situations.  if you have a serious health condition or diagnosis, or if you feel you are in any danger, please call 999 in the United Kingdom or the appropriate emergency responders in other countries.</b></p>
                              <p>By clicking “I accept,” or by downloading, installing, or otherwise accessing or using the service, you agree that you have read and understood, and, as a condition to your use of the service, you agree to be bound by, the following terms and conditions, including Yozoi’ s privacy policy (together, these <b>“terms”</b>). if you are not eligible, or do not agree to the terms, then you do not have our permission to use the service. your use of the service, and Yozoi’ s provision of the service to you, constitutes an agreement by Yozoi and you to be bound by these terms.</p>
                              <p>As described below, these terms provide for the use of arbitration on an individual basis to resolve disputes, rather than court trials, and also limits the remedies available to you in the event of a dispute.</p>
                              <p><b>Summary</b>. The service is a system and a method based on mobile devices, associated software and sensor systems that together enable our customers to obtain services from service providers and benefit from Yozoi artificial intelligence’s potential ability to identify wellbeing problems before they occur and thereby avoid emergencies. The service may include (i) providing users access to information relating to their or their family members activity and wellbeing patterns and possible wellbeing challenges, suggest changes in daily activity patterns, provide links to wellness information, reminders, assessments, and related information (collectively, <b>“Wellbeing Information”</b>) and (ii) enabling users to communicate with service providers and family members or friends nominated as proxies (collectively, <b>“Wellbeing Ecosystem”</b>). As further described below, the Wellbeing Information and the service itself is not personal care, medical or other professional health care advice.  If you use the service to obtain health care advice, your informed consent will be required. The service provides domiciliary support to enable assisted independent living for senior citizens.</p>
                              <p><b>Eligibility</b>. You must be at least 21 years old to use the service. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 21 years old; (b) you have not previously been suspended or removed from the service; and (c) your registration and your use of the service follows all applicable laws and regulations of your country. If you are using the services as a proxy on behalf of an individual (hereinafter the <b>“person”</b>), you represent and warrant that (i) you are an authorised representative of the person with the authority to bind such the person to these Terms, (ii) agree to be bound by these Terms on behalf of the person, and (iii) the person meets the eligibility requirements for the services, as set forth in these Terms. Further, you will be solely responsible for ensuring that the person complies with these Terms. </p>
                              <p><b>Registration</b>. If you are signing up for the service with Yozoi, you must register for an account as customer or service provider. As part of the registration process, you will be asked to select a username and password (your <b>“User Identification”</b>). You are entirely responsible for maintaining the security and confidentiality of your account and password. You agree to notify Yozoi immediately of any unauthorised use of your account or any other breach of security by contacting us on the email <span>users@Yozoi.com</span>. You are responsible for all use of the services occurring under your account and all content posted in your profile or with your account elsewhere on the services. You are responsible for keeping your password confidential and for notifying us if your password has been hacked or stolen. Yozoi will not be liable for any loss that you may incur as a result of someone else using your password or account, either with or without your knowledge. You may be held liable for any losses incurred by Yozoi or another party due to someone else using your account or password. You agree to indemnify, defend, and hold harmless Yozoi and its customers, vendors, affiliates, employees, directors, and agents from and again any claims, losses, damages, and other harm arising in connection with the unauthorised use (including without limitation any use by a person or entity other than you, and any use not expressly permitted by these Terms) of your User Identification. </p>
                              <p><b>No medical advice</b>. THE SERVICE DOES NOT PROVIDE ANY MEDICAL OR OTHER PROFESSIONAL HEALTH AND CARE ADVICE OR SERVICE; NOR IS IT INTENDED TO BE USED TO ASSESS HEALTH CONDITIONS OR SUBSTITUTE FOR PROFESSIONAL MEDICAL ADVICE, OR RELIED UPON FOR DIAGNOSIS OR TREATMENT. Any Wellbeing Information you receive via the service is not medical advice, and is not reviewed or authorised by a physician or healthcare provider.  Use of the service does not, and is not intended to, create, or constitute, a physician-patient relationship with Yozoi, its officers, directors, employees, independent contractors, agents, or anyone acting on behalf of Yozoi. Use of the service may offer certain potential benefits (e.g., automated reminders to engage in certain activities or change activity patterns), but also presents certain potential risks (e.g., currently the Wellbeing Information does not take into account all the specifics of each user’s medical history, the service is not able to exercise medical judgment, security protocols could fail, causing a breach of privacy of personal information, delays could be caused by malfunctioning of sensors or wireless connections). </p>
                              <p>BY AGREEING TO THESE TERMS, YOU REPRESENT AND WARRANT THAT YOU HAVE HAD THE OPPORTUNITY TO CONSIDER THE RISKS AND BENEFITS OF THE USE OF THE SERVICE, INCLUDING THE WELLBEING INFORMATION, AND THAT YOU HAVE PROVIDED VOLUNTARY INFORMED CONSENT TO USE THE SERVICE AND PROVISION OF THE SERVICE TO YOU. </p>
                              <p>Your information will be used in accordance with the provisions of these Terms, the Privacy Policy, and applicable law. By agreeing to these Terms, you are consenting to and authorising the use and disclosure of your information collected in connection with the service. Your permission for the use and/or disclosure of this information will be in effect until you withdraw your permission. You may withdraw your permission at any time by writing to us at <span>users@Yozoi.com</span>.  If you withdraw your permission for the use and disclosure of your information, you can no longer use the service, and the collection, use, and disclosure of new information will stop. However, information that has already been collected may still be used and disclosed in accordance with applicable law.</p>
                              <p><b>Feedback</b>. If you choose to provide input and suggestions regarding problems with or proposed modifications or improvements to the service <b>(“Feedback”)</b>, then you hereby grant Yozoi an unrestricted, perpetual, irrevocable, non-exclusive, fully-paid, royalty-free right to exploit the Feedback in any manner and for any purpose without compensation to you, including to improve the service and create other products and services.</p>
                              <p><b>Ownership; proprietary rights</b>. The service is owned and operated by Yozoi. The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, sensors, software, services, and all other elements of the service <b>(“Components”)</b> provided by Yozoi are protected by intellectual property and other laws. All Components included in the service are the intellectual property of Yozoi or its third-party licensors. Except as expressly authorised by Yozoi, you may not make use of the Components. Yozoi reserves all rights to the Components not granted expressly in these Terms.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Third-party terms</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p><b>Third-Party services and Linked Websites.</b> Yozoi may provide tools and facilities through the service that enable you to export User Content (defined below) to third parties. If you use these tools or facilities, you agree that Yozoi may transfer that information to the applicable third-party service that you indicate. Third-party services are not under Yozoi control, and Yozoi is not responsible for any third-party service’s use of your exported information. The service may also contain links to third party websites. Linked websites are not under Yozoi control, and Yozoi is not responsible for their content.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Consent to electronic communications and text messaging</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p><b>Consent to Electronic Communications.</b> By using the services or providing Personal Information (as defined in the Privacy Policy) to us, you agree that we may communicate with you electronically regarding security, privacy, and administrative issues relating to your use of the services. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the service or sending an email to you, if we have your email address. You may have a legal right to receive this notice in writing. To receive free written notice of a security breach (or to withdraw your consent from receiving electronic notice), please write to us at <span>users@Yozoi.com</span>.</p>
                                <p><b>SMS Text Messages and Push Notifications.</b> When you register with Yozoi, Yozoi may send you SMS text message containing code to verify your phone number or identity and we may also send you messages and notifications via our app <b>(“Messages and Notifications”)</b>. Depending on your current carrier plan, you may incur charges for these Messages and Notifications and agree you will not hold, nor participate in any action which seeks to hold, Yozoi liable for any charges incurred. You acknowledge that any terms between you and any third-party provider (such as, for example, Apple®, Android™, Vodafone, or Google) create no obligation or responsibility on the part of Yozoi, and that Yozoi is not responsible for any failure of warranty by any such third party. Yozoi cannot control certain factors relating to Message and Notifications delivery. You acknowledge that depending on your mobile carrier’s service, and/or availability of local Wi-Fi service, and availability of the Internet, it may not be possible to transmit Messages and Notifications to you successfully. We have no liability for transmission delays or failure to deliver Messages and Notifications.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>User content </h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>Certain features of the service permit Yozoi users to upload or publish content to the service, either manually or through automated data collection and uploading from a wearable device or sensors mounted in your house, including but not limited to activity, location, behaviour pattern, temperature, humidity, position of the arm, body posture, sleep data, step count, body function, heart rate, blood pressure, blood oxygen levels, audio, data, comments, photos, video, images, text, likeness, messages, chats and other types of works <b>(“User Content“)</b>. You retain all rights that you may hold in the User Content that you upload to the service. You hereby consent to Yozoi’ access, use, and processing of your User Content for the purpose of providing and improving the service.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Limited license grant to Yozoi</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>By uploading User Content, you grant Yozoi a worldwide, perpetual, non-exclusive, transferable, royalty-free, fully paid right and license (with the right to sublicense) to host, store, transfer, display, perform, reproduce, create derivative works of, modify for the purpose of formatting, and utilize your User Content in an anonymized form, in whole or in part, in any and all uses now known or later developed in accordance with all applicable laws and regulations and in accordance with the settings on your account and the features of the service you elect to utilize.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Limited license grant to other users</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>By uploading User Content that is shared with other users of the service such as service providers, as a function of the service, you grant those users a non-exclusive license to access and use that User Content as permitted by these Terms and the functionality of the service.  You further acknowledge and agree that Yozoi will not generally perform any editorial functions whatsoever on User Content, and will not be responsible for the actions of other users or third parties that upload User Content or make use of the User Content you upload to the service.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Functionality of do not track technologies </h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>Some browsers may have settings to request that websites “Do Not Track” your activity or usage.  At this time, we do not use services that will accommodate these technologies and we will not comply with your requests on our service.  We do use cookies and other technologies to track your usage and other information about your visit to our service in order to personalise services and ensure your wellbeing.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Usage data</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>You agree that Yozoi may collect and use technical data and related information, including, but not limited to, UDID, and other technical information about your device, system, location, IP address and application software, and peripherals, that is gathered periodically to facilitate the provision of app updates, product support, and other services to you related to the service, and to track and report your activity inside of the service, including for analytics and artificial intelligence predictive purposes. Please see the Privacy Policy for more details regarding the information Yozoi collects, and how it uses and discloses that information.</p>
                                <p><b>Restrictions; data retention.</b> You agree that Yozoi will have no responsibility or liability with respect to any User Content that is processed, transmitted, disclosed, or stored in connection with the service. </p>
                                <p><b>User content representations and warranties.</b> You are responsible for your User Content and the consequences of uploading User Content. By uploading User Content, you affirm, represent, and warrant that:  (A) you are the creator and/or owner of the User Content, or have the necessary licenses, rights, consents, and permissions to authorise Yozoi and other Yozoi users of the service to use and distribute your User Content as necessary to exercise the licenses granted by you in this section, in the manner contemplated by Yozoi, the service, and these Terms; and (B) Your User Content, and the use of your User Content as contemplated by these Terms, does not and will not: (i) infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) slander, defame, libel, or invade the right of privacy, publicity or other property rights of any person or organization; or (iii) cause Yozoi to violate any law or regulation.</p>
                                <p><b>User content disclaimer.</b> We are under no obligation to edit or control User Content that is uploaded and will not be in any way responsible or liable for User Content. You understand that when using the service, you may be exposed to User Content from various users and acknowledge that User Content may be not entirely accurate or agreeable to you. You agree to waive, and do waive, any legal or equitable right or remedy you have or may have against Yozoi with respect to User Content. We expressly disclaim all liability in connection with User Content. If notified by a user or content owner that User Content allegedly does not conform to these Terms, we may investigate the allegation and determine in our sole discretion whether to remove that User Content, which we reserve the right to do at any time and without notice. For clarity, Yozoi does not permit copyright-infringing activities on the service.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Sending messages</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>The service may allow Yozoi users to exchange messages (“Messages”) with each other. Harmful, obscene, abusive, or offensive communications are not welcome in any Messages. If a user sends you an objectionable Message, please notify us by sending an e-mail to users@Yozoi.com. You should exercise discretion, good sense, and sound judgment when sending a Message. You are solely responsible for the content of any Message you send. You agree that Yozoi may monitor Messages for compliance with these Terms. You hereby grant us an assignable, sublicensable, irrevocable license to reproduce and transmit your Messages in connection with facilitating transfer to the intended recipient through the service and for such other purpose as Yozoi may deem appropriate in its sole discretion.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Prohibited conduct</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>BY USING THE SERVICE, YOU AGREE NOT TO:</p>
                                <div className='terms__content__section__content__points'>
                                    <div className='terms__content__section__content__points__num'>
                                        <h4>1.</h4>
                                    </div>
                                    <div className='terms__content__section__content__points__data'>
                                        <h4>use the service for any illegal purpose or in violation of any laws;</h4>
                                    </div>
                                </div>
                                <div className='terms__content__section__content__points'>
                                    <div className='terms__content__section__content__points__num'>
                                        <h4>2.</h4>
                                    </div>
                                    <div className='terms__content__section__content__points__data'>
                                        <h4>violate, or encourage others to violate, any right of a third party, including by infringing or misappropriating any third-party intellectual property right;</h4>
                                    </div>
                                </div>
                                <div className='terms__content__section__content__points'>
                                    <div className='terms__content__section__content__points__num'>
                                        <h4>3.</h4>
                                    </div>
                                    <div className='terms__content__section__content__points__data'>
                                        <h4>interfere with security-related features of the service, including by: (i) disabling or circumventing features that prevent or limit use or copying of any content; or (ii) reverse engineering or otherwise attempting to discover the source code of any portion of the service except to the extent that the activity is expressly permitted by applicable law;</h4>
                                    </div>
                                </div>
                                <div className='terms__content__section__content__points'>
                                    <div className='terms__content__section__content__points__num'>
                                        <h4>4.</h4>
                                    </div>
                                    <div className='terms__content__section__content__points__data'>
                                        <h4>interfere with the operation of the service, including by: (i) uploading or otherwise disseminating any virus, adware, spyware, worm, or other malicious code; (ii) making any unsolicited offer or advertisement to another user of the service; (iii) collecting personal information about another user or third party without consent; or (iv) interfering with or disrupting any network, equipment, or server connected to or used to provide the service;</h4>
                                    </div>
                                </div>
                                <div className='terms__content__section__content__points'>
                                    <div className='terms__content__section__content__points__num'>
                                        <h4>5.</h4>
                                    </div>
                                    <div className='terms__content__section__content__points__data'>
                                        <h4>perform any fraudulent activity including impersonating any person or entity, claiming a false affiliation, accessing any other service account without permission, or falsifying your age or date of birth;</h4>
                                    </div>
                                </div>
                                <div className='terms__content__section__content__points'>
                                    <div className='terms__content__section__content__points__num'>
                                        <h4>6.</h4>
                                    </div>
                                    <div className='terms__content__section__content__points__data'>
                                        <h4>sell or otherwise transfer the access granted under these Terms or any Materials (as defined in Section 6) or any right or ability to view, access, or use any Materials; or</h4>
                                    </div>
                                </div>
                                <div className='terms__content__section__content__points'>
                                    <div className='terms__content__section__content__points__num'>
                                        <h4>7.</h4>
                                    </div>
                                    <div className='terms__content__section__content__points__data'>
                                        <h4>attempt to do any of the acts described in this Section or assist or permit any person in engaging in any of the acts described in this Section.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Modification of these terms</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>We reserve the right to change these Terms at any time upon 7 days’ notice. Please check these Terms periodically for changes. If a change to these Terms materially modifies your rights or obligations, you will be required to accept the modified Terms in order to continue to use the service. Material modifications are effective upon your acceptance of the modified Terms. Immaterial modifications are effective upon publication. Except as expressly permitted in this Section, these Terms may be amended only by a written agreement signed by authorised representatives of the parties to these Terms. Disputes arising under these Terms will be resolved in accordance with the version of these Terms that was in effect at the time the dispute arose.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Term, Termination, and Modification of the service</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>These Terms are effective from when you accept the Terms or first download, install, access, or use the service, and ending when terminated as described in this Section.</p>
                                <p>If you violate any provision of these Terms, your authorisation to access the service and these Terms automatically terminate. In addition, Yozoi may, at its sole discretion, terminate these Terms or your account on the service, or suspend or terminate your access to the service, at any time for any reason or no reason, with or without notice. You may terminate your account and these Terms at any time by clicking the Delete Account button in your account or contacting customer service at <span>users@Yozoi.com</span>.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Effect of termination</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>Upon termination of these Terms: (a) your license rights will terminate and you must immediately cease all use of the service; (b) you will no longer be authorised to access your account or the service; (c) you must pay Yozoi any unpaid amount that was due prior to termination.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Modification of the service</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>Yozoi reserves the right to modify or discontinue the service at any time (including by limiting or discontinuing certain features of the service), temporarily or permanently, without notice to you. Yozoi will have no liability for any change to the service or any suspension or termination of your access to or use of the service.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Indemnity</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>You are responsible for your use of the service, and you will defend and indemnify Yozoi and its officers, directors, employees, consultants, affiliates, subsidiaries and agents (together, the <b>“Yozoi Parties”</b>) from and against every claim brought by a third party, and any related liability, damage, loss, and expense, including reasonable attorneys’ fees and costs, arising out of or connected with: (a) your use of, or misuse of, the service; (b) your violation of any portion of these Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or regulation; (c) your violation of any third party right, including any intellectual property right or publicity, confidentiality, other property, or privacy right; or (d) any dispute or issue between you and any third party. We reserve the right, at our own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you (without limiting your indemnification obligations with respect to that matter), and in that case, you agree to cooperate with our defence of those claims.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Disclaimers; no warranties</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>Yozoi is not a health care provider and cannot and does not diagnose or treat your health conditions. Yozoi provides no endorsement, representation or warranty that any particular recommendation or treatment is or will be safe, effective or appropriate for you. do not use the service for medical emergencies. if you think you may have a medical emergency, call 999 (or the equivalent contact number for emergency services in your region) immediately. <b>Yozoi is not responsible for delayed response times or technical difficulties experienced via the service.</b>  you are solely responsible for keeping your information, user content and wellbeing information up to date.</p>
                                <p>the service and all materials and content available through the service are provided “as is” and on an “as available” basis. Yozoi disclaims all warranties of any kind, whether express or implied, relating to the service and all materials and content available through the service, including: (a) any implied warranty of merchantability, fitness for a particular purpose, title, quiet enjoyment, or non-infringement; and (b) any warranty arising out of course of dealing, usage, or trade. Yozoi does not warrant that the service or any portion of the service, or any materials or content offered through the service, will be uninterrupted, accurate, secure, or free of errors, viruses, or other harmful components, and Yozoi does not warrant that any of those issues will be corrected.</p>
                                <p>No advice or information, whether oral or written, obtained by you from the service or any materials or content available through the service will create any warranty regarding any of the Yozoi entities or the service that is not expressly stated in these terms. we are not responsible for any damage that may result from the service and your dealing with any other service user. you understand and agree that you use any portion of the service at your own discretion and risk, and that we are not responsible for any physical harm, damage to your property (including your computer system or mobile device used in connection with the service) or any loss of data, including user content.</p>
                                <p>However, Yozoi does not disclaim any warranty or other right that Yozoi is prohibited from disclaiming under applicable law.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Limitation of liability</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p>To the fullest extent permitted by law, in no event will the Yozoi entities be liable to you for any indirect, incidental, special, consequential or punitive damages (including damages for loss of profits, goodwill, or any other intangible loss) arising out of or relating to your access to or use of, or your inability to access or use, the service or any materials or content on the service, whether based on warranty, contract, tort (including negligence), statute, or any other legal theory, and whether or not any Yozoi entity has been informed of the possibility of damage.</p>
                                <p>Except as provided in section 16.4(iii) and to the fullest extent permitted by law, the aggregate liability of the Yozoi entities to you for all claims arising out of or relating to the use of or any inability to use any portion of the service or otherwise under these terms, whether in contract, tort, or otherwise, is limited to the greater of: (a) the amount you have paid to Yozoi for access to and use of the service in the 2 months prior to the event or circumstance giving rise to claim; or (b) £100.</p>
                                <p>Each provision of these terms that provides for a limitation of liability, disclaimer of warranties, or exclusion of damages is intended to and does allocate the risks between the parties under these terms. this allocation is an essential element of the basis of the bargain between the parties. each of these provisions is severable and independent of all other provisions of these terms. the limitations in this section will apply even if any limited remedy fails of its essential purpose.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Dispute resolution and arbitration</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p><b>General.</b> In the interest of resolving disputes between you and Yozoi in the most expedient and cost-effective manner, and except as described in Section 16.2, you and Yozoi agree that every dispute arising in connection with these Terms will be resolved by binding arbitration. Arbitration is less formal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, may allow for more limited discovery than in court, and can be subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award. This agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. You understand and agree that, by entering into these terms, you and Yozoi are each waiving the right to a trial by jury or to participate in a class action.</p>
                                <p><b>Exceptions.</b> Despite the provisions of Section 16.1, nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of either party to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable country, state, or local agency if that action is available; (c) seek injunctive relief in a court of law in aid of arbitration; or (d) to file suit in a court of law to address an intellectual property infringement claim.</p>
                                <p> <b>Arbitrator.</b> Any arbitration between you and Yozoi will be settled under the English law and administered by The London Court of Arbitration. The arbitrator has exclusive authority to resolve any dispute relating to the interpretation, applicability, or enforceability of this binding arbitration agreement.</p>
                                <p> <b>Notice of srbitration; process.</b> A party who intends to seek arbitration must first send a written notice of the dispute to the other party by certified mail (signature required) or, only if that other party has not provided a current physical address, then by electronic mail (“Notice of Arbitration”). Yozoi’ address for Notice is: Yozoi Limited, 124 City Road, London, EC4V 2NX, United Kingdom. The Notice of Arbitration must: (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (“Demand”). The parties will make good faith efforts to resolve the claim directly, but if the parties do not reach an agreement to do so within 30 days after the Notice of Arbitration is received, you or Yozoi may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by you or Yozoi must not be disclosed to the arbitrator until after the arbitrator makes a final decision and award, if any. If the dispute is finally resolved through arbitration in your favour, Yozoi will pay you the highest of the following: (i) the amount awarded by the arbitrator, if any; (ii) the last written settlement amount offered by Yozoi in settlement of the dispute prior to the arbitrator’s award; or (iii) £1,000.</p>
                                <p> <b>Fees.</b> If you commence arbitration in accordance with these Terms, Yozoi will reimburse you for your payment of the filing fee, unless your claim is for more than £1,000, in which case the payment of any fees will be decided by the arbitration court and relevant rules. Any arbitration hearing will take place at The London Court of Arbitration, you may choose whether the arbitration will be conducted: (a) solely on the basis of documents submitted to the arbitrator; (b) through a non-appearance-based telephone hearing; or (c) by an in-person hearing as established by the London Court’s arbitration rules. Regardless of the way the arbitration is conducted, the arbitrator must issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the decision and award, if any, are based. The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees or expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator’s ruling on the merits.</p>
                                <p><b>No class actions.</b> You and Yozoi agree that each may bring claims against the other only in your or its individual capacity and not as a plaintiff or class member in any purported class or representative proceeding. Further, unless both you and Yozoi agree otherwise, the arbitrator may not consolidate more than one person’s claims, and may not otherwise preside over any form of a representative or class proceeding.</p>
                                <p><b>Modifications to this arbitration provision.</b> If Yozoi makes any future change to this arbitration provision, other than a change to Yozoi’ address for Notice of Arbitration, you may reject the change by sending us written notice within 30 days of the change to Yozoi’ address for Notice of Arbitration, in which case your account with Yozoi will be immediately terminated and this arbitration provision, as in effect immediately prior to the changes you rejected will survive.</p>
                                <p><b>Enforceability.</b> If any sub-section is found to be unenforceable or if the entirety of this Section is found to be unenforceable, then the entirety of this Section will be null and void and, in that case, the parties agree that the exclusive jurisdiction and venue described in Section xx will govern any action arising out of or related to these Terms.</p>
                                <p><b>Opt-out.</b> Arbitration is not a mandatory condition of these Terms. If you do not want to be subject to this Dispute Resolution provision, you may opt-out of this Dispute Resolution provision by notifying Yozoi in writing of your decision by sending, within 30 days of the date you receive these Terms, an electronic message to <span>Yozoi@Yozoi.com</span>,  stating clearly your full name and intent to opt-out of the Dispute Resolution provision. Should you choose not to opt-out of this Dispute Resolution provision within the 30-day period, you and Yozoi will be bound by the terms of this Dispute Resolution provision. You have the right to consult with counsel of your choice concerning this Dispute Resolution provision. You understand that you will not be subject to retaliation if you exercise your right to opt-out of coverage under this Dispute Resolution provision.</p>
                                <p><b>General terms.</b> These Terms, together with the Privacy Policy and any other agreements expressly incorporated by reference into these Terms, are the entire and exclusive understanding and agreement between you and Yozoi regarding your use of the service. You may not assign or transfer these Terms or your rights under these Terms, in whole or in part, by operation of law or otherwise, without our prior written consent. We may assign these Terms at any time without notice or consent. The failure to require performance of any provision will not affect our right to require performance at any other time after that, nor will a waiver by us of any breach or default of these Terms, or any provision of these Terms, be a waiver of any subsequent breach or default or a waiver of the provision itself. Use of section headers in these Terms is for convenience only and will not have any impact on the interpretation of any provision. Throughout these Terms the use of the word “including” means “including but not limited to”. If any part of these Terms is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible, and the remaining parts will remain in full force and effect.</p>
                                <p><b>Governing law.</b> These Terms are governed by the laws of England, United Kingdom without regard to conflict of law principles. You and Yozoi submit to the exclusive jurisdiction of the English courts for resolution of any lawsuit or court proceeding permitted under these Terms. We operate the service from our offices in London, United Kingdom, and we make no representation that Components included in the service are appropriate or available for use in other locations.</p>
                                <p><b>Privacy policy.</b> Please read the Yozoi Privacy Policy carefully for information relating to our collection, use, storage, disclosure of your personal information. The Yozoi Privacy Policy is incorporated by this reference into and made a part of, these Terms.</p>
                                <p><b>Additional terms.</b> Your use of the service is subject to all additional terms, policies, rules, or guidelines applicable to the service or certain features of the service that we may post on or link to from the service (the <b>“Additional Terms”</b>). All Additional Terms are incorporated by this reference into and made a part of, these Terms.</p>
                            </div>
                        </div>
                        <div className='terms__content__section'>
                            <div className='terms__content__section__heading'>
                                <h2>Protected activity not prohibited</h2>
                            </div>
                            <div className='terms__content__section__content'>
                                <p> You should understand that, insofar as permitted by applicable law, nothing in these Terms will in any way limit or prohibit you from filing a charge or complaint with, or otherwise communicating or cooperating with or participating in any investigation or proceeding <b>(“Protected Activity”)</b> that may be conducted by any government agency or commission <b>(“Government Agencies”)</b>. You understand that in connection with such Protected Activity, you are permitted to disclose documents or other information as permitted by law, and without giving notice to, or receiving authorisation from, Yozoi. Notwithstanding, in making any such disclosures or communications, you agree to take all reasonable precautions to prevent any unauthorised use or disclosure of any information that may constitute Yozoi confidential information to any parties other than the Government Agencies.</p>
                                <p>Contact Information. The service is offered by Yozoi Limited, located at 124 City Road, London, EC4V 2NX, United Kingdom, you may contact us by sending correspondence to that address or by emailing us at <span>users@Yozoi.com</span>. You can access a copy of these Terms by clicking here: <span>https://www.Yozoi.com/Terms-of-service</span>.</p>
                                <p><b>International Use.</b> The service is currently intended for users located within the United Kingdom. We make no representation that the service is appropriate or available for use outside of the United Kingdom. Access to the service from countries or territories or by individuals where such access is illegal is prohibited.</p>
                                <p>Last Updated: 15th March 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsComponent;