import React from 'react'
import './TeamStyles.scss';
import { Helmet } from 'react-helmet-async';
import banner from '../../assets/images/team/banner.jpg';
import team from '../../assets/images/team/team.jpg';
import Slide from 'react-reveal/Slide';

const TeamComponent = () => {
    return (
        <>
            <Helmet>
                <title>Yozoi | Team</title>
                <meta name='description' content='Yozoi | Team' />
                <meta name='keyWords' content='Yozoi | Team' />
            </Helmet>
            <div className='team'>
                <div className='team__container'>
                    <div className='team__banner' style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>Team</h2>
                        </Slide>
                    </div>
                    <div className='team__sections'>
                        <div className='team__section'>
                            <div className='team__section__heading'>
                                <Slide bottom cascade>
                                    <h2>Our team</h2>
                                </Slide>
                                <div className='team__section__line'></div>
                            </div>
                        </div>
                        <div className='team__section__cards-list'>
                            <div className='team__section__card'>
                                <div className='team__section__card__image'>
                                    <Slide bottom>
                                        <img src={team} alt='team' />
                                    </Slide>
                                </div>
                                <div className='team__section__card__name'>
                                    <Slide bottom>
                                        <h3>Dosten baluch</h3>
                                    </Slide>
                                </div>
                                <div className='team__section__card__links'>
                                    <Slide bottom>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-facebook' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-instagram' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-linkedin' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-twitter' />
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                            <div className='team__section__card'>
                                <div className='team__section__card__image'>
                                    <Slide bottom>
                                        <img src={team} alt='team' />
                                    </Slide>
                                </div>
                                <div className='team__section__card__name'>
                                    <Slide bottom>
                                        <h3>Praveen kumar</h3>
                                    </Slide>
                                </div>
                                <div className='team__section__card__links'>
                                    <Slide bottom>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-facebook' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-instagram' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-linkedin' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-twitter' />
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                            <div className='team__section__card'>
                                <div className='team__section__card__image'>
                                    <Slide bottom>
                                        <img src={team} alt='team' />
                                    </Slide>
                                </div>
                                <div className='team__section__card__name'>
                                    <Slide bottom>
                                        <h3>Kevin doughty</h3>
                                    </Slide>
                                </div>
                                <div className='team__section__card__links'>
                                    <Slide bottom>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-facebook' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-instagram' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-linkedin' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-twitter' />
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                            <div className='team__section__card'>
                                <div className='team__section__card__image'>
                                    <Slide bottom>
                                        <img src={team} alt='team' />
                                    </Slide>
                                </div>
                                <div className='team__section__card__name'>
                                    <Slide bottom>
                                        <h3>Mark mann</h3>
                                    </Slide>
                                </div>
                                <div className='team__section__card__links'>
                                    <Slide bottom>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-facebook' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-instagram' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-linkedin' />
                                        </div>
                                        <div className='team__section__card__link'>
                                            <i className='fa fa-twitter' />
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamComponent;