import React from 'react';
import './HomeVisits.scss';
import { Helmet } from 'react-helmet-async';
import banner from '../../assets/images/service-page.jpg';
import bedding from '../../assets/images/home services/bedding.png';
import cleaning from '../../assets/images/home services/cleaning.png';
import footcare from '../../assets/images/home services/foot care.png';
import gardening from '../../assets/images/home services/gardening.png';
import haircare from '../../assets/images/home services/hair care.png';
import handyperson from '../../assets/images/home services/handy person.png';
import laundry from '../../assets/images/home services/laundry.png';
import mobilityassistance from '../../assets/images/home services/mobility assistance.png';
import pets from '../../assets/images/home services/pets.png';
import preparingmeals from '../../assets/images/home services/preparing meals.png';
import shoppingassistance from '../../assets/images/home services/shopping assistance.png';
import security from '../../assets/images/home services/security.png';
import Slide from 'react-reveal/Slide';

const HomeVisits = () => {
    return (
        <>
            <Helmet>
                <title>Yozoi | Home Visits</title>
                <meta name='description' content='Yozoi | Home Visits' />
                <meta name='keyWords' content='Yozoi | Home Visits' />
            </Helmet>
            <div className='visits'>
                <div className='visits__container'>
                    <div className='visits__banner' style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>Services</h2>
                        </Slide>
                    </div>
                    <div className='visits__sections'>
                        <div className='visits__section'>
                            <div className='visits__section__heading'>
                                <Slide bottom cascade>
                                    <h2>Home support visits</h2>
                                </Slide>
                                <div className='visits__section__line'></div>
                            </div>
                            <div className='visits__section__main'>
                                <div className='visits__section__container' id='bedding'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={bedding} alt='Bedding' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>                        <Slide right>
                                            <h3>Bedding</h3>
                                            <p>Change of bedding in the customer’s home with fresh bedding provided by the customer. Please also request laundry service if you require bedding to be laundered and dried either in your home or taken away and delivered back after laundering.</p>
                                        </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='cleaning'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={footcare} alt='Cleaning ' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                <h3>Cleaning </h3>
                                                <p>Our service providers can clean all in-door areas of your home including vacuuming, mopping, dusting, and polishing. Outdoor cleaning services are also available including patios, driveways, and gardens.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='footcare'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={cleaning} alt='Footcare ' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide right>
                                                <h3>Footcare </h3>
                                                <p>Cutting nails, hydrating, and massaging feet for improved circulation.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='gardening'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={gardening} alt='Gardening' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                <h3>Gardening</h3>
                                                <p>All routine garden maintenance work such as cutting grass, trimming hedges and plants, and clearing garden waste.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='haircare'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={haircare} alt='Haircare ' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide right>
                                                <h3>Haircare </h3>
                                                <p>Full barber services for men and hair saloon services for ladies in the comfort of your own home.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='handyperson'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={handyperson} alt='Handyperson' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                <h3>Handyperson</h3>
                                                <p>Routine home maintenance such as changing light bulbs, batteries, and fuses. Installing fixture, mobility equipment, goods such as new fridges, toasters, kettle and so on. Please specify as accurately as possible in your service request.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='laundry'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={laundry} alt='Laundry' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide right>
                                                <h3>Laundry</h3>
                                                <p>Our service providers can do all your laundry either in your own home or take it away for laundering and delivery back to you. Please specify in your service request.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='mobility-assistance'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={mobilityassistance} alt='Mobility assistance' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                <h3>Mobility assistance</h3>
                                                <p>We can provide transport and accompany you to appointments such as visits to the doctor, dentist or pharmacy as well as visits to cafes, restaurants and community events.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='pets'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={pets} alt='Pets' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide right>
                                                <h3>Pets</h3>
                                                <p>Feeding pets, walking dogs and visits to the vet. </p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='preparing-meals'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={preparingmeals} alt='Preparing meals' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                <h3>Preparing meals</h3>
                                                <p>Do you need a nutritional plan to optimise health or need a service to prepare and serve your meals. Yozoi service provider can help make a nutritional plan, cook meals in you home daily or as and when you need, serve you food and tidy up kitchen. Alternatively, home cooked meals can be delivered and served to you at home. Contact us to assess and specify your needs.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='shopping-assistance'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide left>
                                                <img src={shoppingassistance} alt='Shopping assistance' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide right>
                                                <h3>Shopping assistance</h3>
                                                <p>We can provide transport and accompany you on shopping trips to the supermarkets or other outlets. Alternatively, our service providers can do the shopping for you, deliver to your home and store in your home to fit with your requirements.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                                <div className='visits__section__container' id='security-assistance'>
                                    <div className='visits__section__container__left'>
                                        <div className='visits__section__container__left__image'>
                                            <Slide right>
                                                <img src={security} alt='Security assistance' />
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='visits__section__container__right'>
                                        <div className='visits__section__container__right__content'>
                                            <Slide left>
                                                <h3>Security assistance</h3>
                                                <p>Do you need a trusted person to visit to check around and in your house or be present whilst you use the bathroom in case you have an accident? Yozoi can provide such assistance 24/7 and every day of the year whenever you need it.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeVisits;