import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
// import PhoneInput from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';
import Flag from 'react-flagkit';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const DemoValidate = () => {
    const [isToggle, setIsToggle] = useState(false);
    const [value, setValue] = useState(false);
    const [validated, setValidated] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState('');

    const getFlag = () => {
        console.log(phoneNumber.split(' ')[0])
        return <Flag code={+44} />
        // if (phoneNumber) {
        //     const countryCode = phoneNumber.split(' ')[0];
        //     return <Flag code={countryCode} />;
        // } else {
        //     const countryCode = +44;
        //     return <Flag code={countryCode} />;
        // }
        return null;
    };

    // const handleSubmit = (event) => {
    //     // const form = event.currentTarget;
    //     console.log(event)
    //     // if (form.checkValidity() === false) {
    //     //     event.preventDefault();
    //     //     event.stopPropagation();
    //     // }

    //     // setValidated(true);
    // };
    return (
        <div className='service' style={{ margin: '10vw 4vw' }}>
            {/* <Form noValidate validated={validated} onSubmit={handleSubmit}> */}
            {/* <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="First name"
                        // defaultValue="Mark"
                        />
                        <Form.Control.Feedback type='invalid'>Please provide first name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Last name"
                        // defaultValue="Otto"
                        />
                        <Form.Control.Feedback type='invalid'>Please provide last name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                        <Form.Label>email</Form.Label>
                        <InputGroup hasValidation>
                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                            <Form.Control
                                type="text"
                                placeholder="Email id"
                                aria-describedby="inputGroupPrepend"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please choose a Email.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row> */}
            {/* <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="City" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid city.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom04">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" placeholder="State" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid state.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationCustom05">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" placeholder="Zip" required />
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid zip.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row> */}
            {/* <Form.Group className="mb-3">
                    <Form.Check
                        required
                        label="Agree to terms and conditions"
                        feedback="You must agree before submitting."
                        feedbackType="invalid"
                    />
                </Form.Group> */}
            {getFlag()}
            <PhoneInput
                // defaultCountry='gb'
                country={'gb'}
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
                maxLength={10}
            />
            <Button type="submit">Submit form</Button>
            {/* </Form> */}
        </div>
    )
}

export default DemoValidate;