import React, { useEffect, useState } from 'react';
import './home.scss';
import googleplay from '../../assets/images/google playb.png';
import appleplay from '../../assets/images/app Storeb.png';
import banner from '../../assets/images/banner.jpg';
import banner2 from '../../assets/images/banner2.jpg';
import how1 from '../../assets/images/home page icons/Mobile Application.png';
import how2 from '../../assets/images/home page icons/Technology.png';
import how3 from '../../assets/images/home page icons/Safety.png';
import why1 from '../../assets/images/home page icons/Flexible Working.png';
import why2 from '../../assets/images/home page icons/Pay and Conditions.png';
import why3 from '../../assets/images/home page icons/Training and Support.png';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Tooltip from '@mui/material/Tooltip';


const Home = (props) => {
    const navigate = useNavigate();
const [activeBanner,setActiveBanner] = useState(1);

    const background =[
        {
            image: banner
        },
        {
            image: banner2
        }
    ]
const prevSlide = ()=>{
    setActiveBanner(activeBanner==0? background.length-1:activeBanner-1)
}
    useEffect(() => {
        const nextSlideIntervalId = setInterval(() => {
            prevSlide();
        }, 3000);
        
        document.title = props.title;
        return () => clearInterval(nextSlideIntervalId);
    },[activeBanner]);

    const handleBookService = () => {
        navigate('/registration/service');
        window.scrollTo(0, 0);
    }

    const handleServiceProvider = () => {
        // navigate('/registration/service-provider');
        navigate('/fill');
        window.scrollTo(0, 0);
    }

    const handleWhy = () => {
        navigate('/why-work-at-yozoi')
    }

    const handleLearn = () => {
        navigate('/learn-about-yozoi-services')
    }


    return (
        <>
            <Helmet>
                <title>Yozoi | Home</title>
                <meta name='description' content='Yozoi | Home' />
                <meta name='keyWords' content='Yozoi | Home' />
            </Helmet>
            <div className='home'>
                <div className='home__container'>
                    <div className='banner' 
                    style={{
                        backgroundImage: `url(${background[activeBanner].image})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right top'
                    }}
                    >
                        <div className='banner__section'>
                            <div className='banner__container'>
                                <div className='banner__left-card'>
                                    <Fade bottom>
                                        <h2>Become a <br /> service provider</h2>
                                        <p>Help your local community, work when you like and get paid</p>
                                        <button className='sign-up' onClick={handleServiceProvider}>Sign up</button>
                                        <h6 onClick={handleWhy}><span>Learn about being a Yozoi service provider</span></h6>
                                    </Fade>
                                    <div className='banner__left-card__store'>
                                        <Fade bottom>
                                            <h5>Download service provider app</h5>
                                        </Fade>
                                        <div className='banner__left-card__store__images'>
                                            <Fade bottom>
                                                <Tooltip title='coming soon, please register and send message to users@yozoi.com if you need assistance' placement='bottom'>
                                                    <img src={googleplay} alt='google-playstore' />
                                                </Tooltip>
                                                <Tooltip title='coming soon, please register and send message to users@yozoi.com if you need assistance' placement='bottom'>
                                                    <img src={appleplay} alt='apple-playstore' />
                                                </Tooltip>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                                <div className='banner__right-card'>
                                    <Fade bottom>
                                        <h2>Book a<br />service</h2>
                                        <p>Use our on-demand services mobile app to get 24/7 support </p>
                                        <button className='sign-up' onClick={handleBookService}>Sign up</button>
                                        <h6 onClick={handleLearn}><span>Learn about Yozoi services</span></h6>
                                    </Fade>
                                    <div className='banner__right-card__store'>
                                        <Fade bottom>
                                            <h5>Download customer app</h5>
                                        </Fade>
                                        <div className='banner__right-card__store__images'>
                                            <Fade bottom>
                                                <Tooltip title='coming soon, please register and send message to users@yozoi.com if you need assistance' placement='bottom'>
                                                    <img src={googleplay} alt='google-playstore' />
                                                </Tooltip>
                                                <Tooltip title='coming soon, please register and send message to users@yozoi.com if you need assistance' placement='bottom'>
                                                    <img src={appleplay} alt='apple-playstore' />
                                                </Tooltip>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='how-work'>
                        <div className='how-work__container'>
                            <div className='how-work__heading'>
                                <Slide bottom cascade>
                                    <h2>How we work</h2>
                                </Slide>
                                <div className='how-work__heading__line'></div>
                            </div>
                            <div className='how-work__section'>
                                <div className='how-work__card'>
                                    <div className='how-work__card__icon'>
                                        <Slide bottom>
                                            <img src={how1} alt='Mobile Application' />
                                        </Slide>
                                    </div>
                                    <div className='how-work__card__content'>
                                        <Slide bottom>
                                            <h2>Mobile application</h2>
                                            <p>Dedicated customer and service provider apps enabling customers to book services and service providers to provide services. Real-time service tracking, billing, 2-way feedback and ratings. </p>
                                        </Slide>
                                    </div>
                                </div>
                                <div className='how-work__card'>
                                    <div className='how-work__card__icon'>
                                        <Slide bottom>
                                            <img src={how2} alt='Technology' />
                                        </Slide>
                                    </div>
                                    <div className='how-work__card__content'>
                                        <Slide bottom>
                                            <h2>Technology</h2>
                                            <p>Latest technologies in sensors, big data analytics and artificial intelligence for delivering customised services, monitoring client’s wellbeing and using predictive AI for detection of wellbeing issues and avoidance of emergencies.</p>
                                        </Slide>
                                    </div>
                                </div>
                                <div className='how-work__card'>
                                    <div className='how-work__card__icon'>
                                        <Slide bottom>
                                            <img src={how3} alt='Safety' />
                                        </Slide>
                                    </div>
                                    <div className='how-work__card__content'>
                                        <Slide bottom>
                                            <h2>Safety</h2>
                                            <p>The technologies integrated in Yozoi app and our operating procedures provide customers and service providers with the best possible standards in safety and security. </p>
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='why-work'>
                        <div className='why-work__container'>
                            <div className='why-work__heading'>
                                <Slide bottom cascade>
                                    <h2>Why work as a Yozoi service provider</h2>
                                </Slide>
                                <div className='why-work__heading__line'></div>
                            </div>
                            <div className='why-work__section'>
                                <div className='why-work__card'>
                                    <div className='why-work__card__icon'>
                                        <Slide bottom>
                                            <img src={why1} alt='Flexible working' />
                                        </Slide>
                                    </div>
                                    <div className='why-work__card__content'>
                                        <Slide bottom>
                                            <h2>Flexible working</h2>
                                            <p>Working with Yozoi means that you are in control, you decide when and where you want to work and how you want to fit-in work schedule with your other life commitments. </p>
                                        </Slide>
                                    </div>
                                </div>
                                <div className='why-work__card'>
                                    <div className='why-work__card__icon'>
                                        <Slide bottom>
                                            <img src={why2} alt='Pay and conditions' />
                                        </Slide>
                                    </div>
                                    <div className='why-work__card__content'>
                                        <Slide bottom>
                                            <h2>Pay and conditions</h2>
                                            <p>We aspire to always stay in front of the pack in terms of pay and other incentives for people working with us to help their local communities. This includes guarantee that you will earn more than the National Living Wage and where eligible, holiday pay and a pension plan. </p>
                                        </Slide>
                                    </div>
                                </div>
                                <div className='why-work__card'>
                                    <div className='why-work__card__icon'>
                                        <Slide bottom>
                                            <img src={why3} alt='Training and support' />
                                        </Slide>
                                    </div>
                                    <div className='why-work__card__content'>
                                        <Slide bottom>
                                            <h2>Training and support</h2>
                                            <p>The Yozoi app continuously watches the services you do and how you perform them, this enables us to give you support in real-time and assess training requirements.</p>
                                        </Slide>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;