import React from 'react';
import './WhyWorkStyles.scss';
import { Helmet } from 'react-helmet-async';
// import banner from '../../assets/images/about.jpg';
import banner from '../../assets/images/why work/banner1.jpg';
import img2 from '../../assets/images/why work/screen1.png';
import img3 from '../../assets/images/why work/screen2.png';
// import img3 from '../../assets/images/why work/why work-2.png';
// import img1 from '../../assets/images/wireframe/CX1.png';
// import img2 from '../../assets/images/wireframe/2.png';
// import img3 from '../../assets/images/wireframe/1.png';
import flexible from '../../assets/images/why work/flexible work.jpg';
import great from '../../assets/images/why work/great support.jpg';
import pay from '../../assets/images/why work/pay and incentives.jpg';
import Slide from 'react-reveal/Slide';

const WhyWorkComponents = () => {
    return (
        <>
            <Helmet>
                <title>Yozoi | Why Work at Yozoi</title>
                <meta name="description" content="Why Work at Yozoi" />
                <meta name='keyWords' content='Why Work at Yozoi' />
            </Helmet>

            <div className='why'>
                <div className='why__container'>
                    <div className='why__banner' style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: 'cover', backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center top'
                    }}>
                        <Slide left>
                            <h2>Why work at Yozoi</h2>
                        </Slide>
                    </div>
                    <div className='why__sections-main'>
                        <div className='why__sections'>
                            <div className='why__section__mission'>
                                <div className='why__section__mission__heading'>
                                    {/* <Slide bottom cascade>
                                        <h2>Why work as a Yozoi service provider?</h2>
                                    </Slide> */}
                                    {/* <div className='why__section__mission__line'></div> */}
                                    {/* <Slide bottom>
                                        <p>Yozoi means hello life, Yo is hello and Zoi means life. We offer on-demand 24/7 domiciliary support services to busy professionals, retirees, and senior citizens to ease the pressures of daily life. We differentiate ourselves through our service principles of flexibility, quality, care, compassion and the latest in digital technologies. Our service providers deliver a bespoke and professional service with the utmost kindness and concern for the well-being of our customers. The services are booked through the Yozoi app, the technology embedded in our app, monitors the service to ensure utmost quality. The services include those shown below, please contact us if the service you need is not in the list, we are always happy to offer customised services.</p>
                                    </Slide> */}
                                </div>
                                <div className='why__section__mission__heading1'>
                                    <Slide bottom>
                                        <h2>Our commitment to ensuring the wellbeing and success of our service providers</h2>
                                    </Slide>
                                </div>
                                <div className='why__section__mission__container'>
                                    <div className='why__section__mission__container__left'>
                                        <div className='why__section__mission__container__left__content'>
                                            <Slide left>
                                                <h6>The best mobile app for domiciliary services</h6>
                                                <h4>Our Mission is “To be the best on-demand mobile technology platform for domiciliary services”</h4>
                                                <p>Our mobile applications app takes the hassle out of domiciliary support work, you can focus on doing your job as and when your work schedule, whilst we take care of all the administrative, regulatory, customer relationship management and payment processing. At the same time as you work, Yozoi monitors the service and gives you support in real time as and when needed.</p>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className='why__section__mission__container__right'>
                                        <div className='why__section__mission__container__right__images'>
                                            <div className='why__section__mission__container__right__images__left'>
                                                <Slide right>
                                                    {/* <img src={img1} alt='' /> */}
                                                    <img src={img2} alt='' />
                                                </Slide>
                                            </div>
                                            <div className='why__section__mission__container__right__images__right'>
                                                {/* <Slide right>
                                                    <img src={img2} alt='' />
                                                </Slide> */}
                                                <Slide right>
                                                    <img src={img3} alt='' />
                                                </Slide>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='why__section__flexible'>
                        <div className='why__section__flexible__left'>
                            <Slide left>
                                <img src={flexible} alt='flexible' />
                            </Slide>
                        </div>
                        <div className='why__section__flexible__right'>
                            <Slide right>
                                <h3>Flexible working model</h3>
                                <p>Working with Yozoi means that you are in control, you decide when you want to work and how you want to fit-in work schedule with your other life commitments. We provide you with flexible working and earning opportunities and you decide when and how many hours of the day or night that you wish to work.</p>
                            </Slide>
                        </div>
                    </div>
                    <div className='why__section__flexible'>
                        <div className='why__section__flexible__left'>
                            <Slide right>
                                <img src={great} alt='flexible' />
                            </Slide>
                        </div>
                        <div className='why__section__flexible__right'>
                            <Slide left>
                                <h3>Great support and training</h3>
                                <p>The Yozoi app continuously watches the services you do and how you perform them, this enables us to give you support in real-time and assess training requirements. Depending on the preferences you have set in your account, Yozoi can make training and career development recommendations and, dependent on eligibility, offer you free training. We do this with the desire to see you develop to your optimum and provide customers the  best possible service.</p>
                            </Slide>
                        </div>
                    </div>
                    <div className='why__section__flexible'>
                        <div className='why__section__flexible__left'>
                            <Slide left>
                                <img src={pay} alt='flexible' />
                            </Slide>
                        </div>
                        <div className='why__section__flexible__right'>
                            <Slide right>
                                <h3>Pay and incentives </h3>
                                <p>We aspire to always stay in front of the pack in terms of pay and other incentives for people working with us in domiciliary support services and helping their local communities. This includes guarantee that you will earn at least the National Living Wage and where eligible, holiday pay and a pension plan. </p>
                            </Slide>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyWorkComponents;